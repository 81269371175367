<template>
  <v-dialog
    v-model="showDialogValue"
    width="1040"
    max-height="90vh"
    class="dialog-content-outer"
  >
    <div class="dialog-content">
      <div class="dialog-content-block dialog-content-margin">
        <button
          class="div-button"
          @click="movePage('Dashboard')"
        >
          <div class="link-head mb-0">
            <div class="page-title">
              <span>ダッシュボード</span>
              <span class="page-description">定期的に分析する「お気に入り店舗の条件」や「近隣の比較店舗」を事前登録できます</span>
            </div>
            <div class="link-chevron">
              <img src="@/assets/svg/chevron-right.svg">
            </div>
          </div>
        </button>
      </div>
      <div class="dialog-content-block dialog-content-margin">
        <div class="link-head">
          <div class="page-title">
            <span>店舗分析</span>
            <span class="page-description">店舗を一つ選択し、さまざまな角度から分析することができます</span>
          </div>
        </div>
        <div class="page-link">
          <button
            class="column_1"
            @click="movePage('ShopAnalyticsVisitor')"
          >
            来店人数
          </button>
          <button
            class="column_2"
            @click="movePage('ShopAnalyticsTrendVisitor')"
          >
            曜日/時間別人数
          </button>
          <button
            class="column_3"
            @click="movePage('ShopAnalyticsPersona')"
          >
            ペルソナ特性
          </button>
          <button
            class="column_4"
            @click="movePage('ShopAnalyticsVisitEngagement')"
          >
            来店特性
          </button>
        </div>
        <div class="page-link">
          <button
            class="column_1"
            @click="movePage('ShopAnalyticsBizArea')"
          >
            商圏分析
          </button>
        </div>
      </div>
      <div class="dialog-content-block dialog-content-margin">
        <div class="link-head">
          <div class="page-title">
            <span>店舗比較</span>
            <span class="page-description">「店舗比較リスト」に事前に登録された店舗同士を比較して分析できます</span>
          </div>
        </div>
        <div class="page-link">
          <button
            class="column_1"
            @click="movePage('StoreCompareVisitor')"
          >
            来店人数
          </button>
          <button
            class="column_2"
            @click="movePage('StoreCompareTrendVisitor')"
          >
            曜日/時間別人数
          </button>
          <button
            class="column_3"
            @click="movePage('StoreComparePersona')"
          >
            ペルソナ特性
          </button>
          <button
            class="column_4"
            @click="movePage('StoreCompareVisitEngagement')"
          >
            来店特性
          </button>
        </div>
        <div class="page-link">
          <button
            class="column_1"
            @click="movePage('StoreCompareBizArea')"
          >
            商圏分析
          </button>
        </div>
      </div>
      <div class="dialog-content-block dialog-content-margin">
        <button
          class="div-button"
          @click="movePage('StoreArea')"
        >
          <div class="link-head mb-0">
            <div class="page-title">
              <span>地域シェア</span>
              <span class="page-description">店舗を一つ選択すると、指定サイズの商圏における店舗別の集客シェアが把握できます</span>
            </div>
            <div class="link-chevron">
              <img src="@/assets/svg/chevron-right.svg">
            </div>
          </div>
        </button>
      </div>
      <div class="dialog-content-block">
        <div class="link-head">
          <div class="page-title">
            <span>その他メニュー</span>
            <span class="page-description">ユーザー情報の変更や、サービス利用に関する情報はこちらをご覧ください</span>
          </div>
        </div>
        <div class="page-link">
          <button
            class="column_1"
            @click="movePage('NewsList')"
          >
            お知らせ
          </button>
          <button
            class="column_2"
            @click="movePage('UserSetting')"
          >
            ユーザー設定
          </button>
          <button
            class="column_3"
            @click="movePage('Manual')"
          >
            マニュアル
          </button>
          <button
            class="column_4"
            @click="movePage('FAQ')"
          >
            FAQ・お問い合わせ
          </button>
        </div>
        <div class="page-link">
          <button
            class="column_1"
            @click="logout"
          >
            ログアウト
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { logout as authLogout } from "@/commons/axios/auth";
import { notifyErrorMessage } from "@/plugins/notification";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "DialogMenu",
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const showDialogValue = ref(false);

    const logout = () => {
      authLogout().then(() => {
          window.dataLayer.push(function (this: any) {
            this.reset();
          });
          router.push({ name: "Login" });
        })
        .catch(() => {
          notifyErrorMessage("ログアウトできませんでした。");
        });
    };
    const movePage = (name: string) => {
      const { start, end } = {
        start: convertHyphenDelimiter(store.state.startDate),
        end: convertHyphenDelimiter(store.state.endDate),
      };
      const { startMonth, endMonth } = {
        startMonth: convertHyphenDelimiter(store.state.startMonth),
        endMonth: convertHyphenDelimiter(store.state.endMonth),
      };
      const storeId = store.state.selectedStore
        ? store.state.selectedStore.storeId
        : null;
      const comparisonGroupId = store.state.selectedComparisonGroup
        ? store.state.selectedComparisonGroup.id
        : null;

      switch (name) {
        case "ShopAnalyticsVisitor":
          router
            .push({
              name: name,
              params: { id: storeId },
              query: {
                period: `${start}_${end}`,
                unit: "date",
                subIndicator: "none",
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "ShopAnalyticsTrendVisitor":
          router
            .push({
              name: name,
              params: { id: storeId },
              query: {
                period: `${start}_${end}`,
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "ShopAnalyticsPersona":
          router
            .push({
              name: name,
              params: { id: storeId },
              query: {
                period: `${start}_${end}`,
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "ShopAnalyticsVisitEngagement":
          router
            .push({
              name: name,
              params: { id: storeId },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "ShopAnalyticsBizArea":
          router
            .push({
              name: name,
              params: { id: storeId },
              query: {
                period: `${startMonth}_${endMonth}`,
                radius: "5",
                v_radius: "5",
                unit: "town",
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "StoreCompareVisitor":
          router
            .push({
              name: name,
              params: {
                id: comparisonGroupId,
              },
              query: {
                period: `${start}_${end}`,
                unit: "date",
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "StoreCompareTrendVisitor":
          router
            .push({
              name: name,
              params: {
                id: comparisonGroupId,
              },
              query: {
                period: `${start}_${end}`,
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "StoreComparePersona":
          router
            .push({
              name: name,
              params: {
                id: comparisonGroupId,
              },
              query: {
                period: `${start}_${end}`,
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "StoreCompareVisitEngagement":
          router
            .push({
              name: name,
              params: {
                id: comparisonGroupId,
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        case "StoreCompareBizArea":
          router
            .push({
              name: name,
              params: {
                id: store.state.selectedComparisonGroup?.id,
              },
              query: {
                period: `${start}_${end}`,
                radius: "5",
                v_radius: "5",
                unit: "town",
              },
            })
            .catch(() => undefined);
          showDialogValue.value = false;
          return;

        default:
          router.push({ name: name }).catch(() => undefined);
          showDialogValue.value = false;          
          return;
      }
    };

    watch(() => props.showDialog, (newValue: boolean) => {
      showDialogValue.value = newValue;
    });

    watch(showDialogValue, (newValue: boolean) => {
      if (!newValue) {
        emit("close");
      }
    });

    return {
      showDialogValue,
      logout,
      movePage,
    };
  },
});
</script>

<style lang="scss">
.dialog-content-outer .v-overlay__content {
  overflow-y: scroll !important;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 6px;
}
</style>

<style lang="scss" scoped>
.dialog-content {
  padding: 49px 40px 52px 40px;

  .dialog-content-block {
    text-align: left;

    .link-head {
      width: 100%;
      height: 52px;
      background: #eeeeee 0% 0% no-repeat padding-box;
      padding: 14px 18px 14px 20px;
      margin-bottom: 10px;
      align-items: center;
      display: flex;

      .page-title {
        font: normal normal bold 16px/1 Noto Sans CJK JP;
        color: #222222;
        text-align: left;
        float: left;
        width: 100%;
        display: flex;
        align-items: center;

        .page-description {
          margin-left: 8px;
          font: normal normal normal 13px/1 Noto Sans CJK JP;
          color: #666666;
          text-align: left;
        }
      }

      .link-chevron {
        float: right;
      }
    }
    .page-link {
      margin: 8px 0px;

      button {
        width: 234px;
        height: 44px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #cccccc;
        border-radius: 4px;
        font: normal normal normal 14px/1 Noto Sans CJK JP;
        color: #444444;
        padding: 12px 18px;
        text-align: left;
      }

      button.column_1,
      button.column_2,
      button.column_3 {
        margin-right: 4px;
      }

      button.column_2,
      button.column_3,
      button.column_4 {
        margin-left: 4px;
      }
    }
    .div-button {
      width: 100%;
    }
  }
  .dialog-content-margin {
    margin-bottom: 30px;
  }
}
</style>
