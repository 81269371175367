<template>
  <div>
    <div class="compare-list-header">
      <CustomInput
        v-model="search"
        placeholder="リスト名・店舗名の一部で検索"
        width="360px"
        height="44px"
      />
      <div class="number-of-registrations">
        登録数：{{ filteredComparisonFavorites.length }}件
      </div>
      <v-spacer />
      <CustomButton
        variant="primary"
        size="md"
        :disabled="favoritesLoading"
        @click="dialog = true"
      >
        + 条件を作成
      </CustomButton>
    </div>
    <LoadingImg
      v-if="favoritesLoading"
      height="600px"
    />
    <FavoriteListTable
      v-else-if="filteredComparisonFavorites.length > 0"
      :favorites="filteredComparisonFavorites"
      :sort-by="sortBy"
      :store-loading="storesLoading"
      @fetch-data="fetchData"
      @set-sort-by="setSortBy($event)"
    />
    <NoList v-else />
    <FavoriteConditionDialog
      v-model="dialog"
      @click-search="clickSearch($event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed } from "vue";
import FavoriteListTable from "@/features/Dashboard/components/FavoriteListTable.vue";
import NoList from "@/features/Dashboard/components/NoList.vue";
import FavoriteConditionDialog from "@/features/Dashboard/components/FavoriteConditionDialog.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { getStoresFavorites } from "@/features/Dashboard/axios";
import { Favorite } from "@/features/Dashboard/types";
import * as notify from "@/plugins/notification";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { serializeBase64 } from "@/commons/utils";

export default defineComponent({
  name: "FavoriteList",
  components: {
    FavoriteListTable,
    NoList,
    LoadingImg,
    FavoriteConditionDialog,
  },
  setup(){
    const store = useStore();
    const router = useRouter();
    const favoritesLoading = ref(false);
    const storesLoading = ref(false);
    const favorites = ref<Favorite[]>([]);
    const search = ref("");
    const sortBy = reactive({ column: "updatedAt", desc: true } as {
      column: "name" | "updatedAt";
      desc: boolean;
    });
    const dialog = ref(false);

    const filteredComparisonFavorites = computed((): Favorite[] =>
    {
      if (!favorites.value.length) return [];
      return favorites.value
        .filter((group) => (group.name as string).indexOf(search.value) > -1)
        .sort((a, b) => {
          if (sortBy.column === "name")
            return sortBy.desc
              ? (a.name as string).localeCompare(b.name as string, "ja")
              : (b.name as string).localeCompare(a.name as string, "ja");
          if (sortBy.column === "updatedAt")
            return sortBy.desc
              ? (a.updated_at as string) > (b.updated_at as string)
                ? -1
                : 1
              : (a.updated_at as string) < (b.updated_at as string)
              ? -1
              : 1;
          return Number(a.id) - Number(b.id);
        });
    });
    const fetchData = async () =>{
      favoritesLoading.value = true;
      storesLoading.value = true;
      // お気に入り条件一覧
      await getStoresFavorites()
        .then((res) => {
          favorites.value = res.data.favorites;
        })
        .catch(() => {
          notify.notifyErrorMessage("お気に入り条件の取得に失敗しました。");
        });
      favoritesLoading.value = false;
      // 店舗一覧
      if (!store.state.stores.length)
        await store.dispatch("fetchStores");
      // 選択可能な店舗形態一覧
      if (!store.state.storeTypes.length)
        await store.dispatch("fetchStoreTypes");
      // 都道府県一覧
      if (!store.state.prefectures.length)
        await store.dispatch("fetchPrefectures");
      // 市区町村一覧
      if (!store.state.cities.length)
        await store.dispatch("fetchCities");
      storesLoading.value = false;
    };
    const setSortBy = (event: { type: "name" | "updatedAt" }) => {
      if (sortBy.column === event.type) {
        sortBy.desc = !sortBy.desc;
      } else {
        sortBy.column = event.type;
        sortBy.desc = true;
      }
    };
    const clickSearch = (event: { favorite: Favorite }) => {      
      router.push({
        name: "FavoriteCreate",
        // NOTE: router 経由で props にデータを渡す場合、params は string しか許容されないためBase64でシリアライズ
        params: { favorite: serializeBase64(event.favorite) },
      });
    };

    fetchData();

    return {
      favoritesLoading,
      storesLoading,
      favorites,
      search,
      sortBy,
      dialog,
      filteredComparisonFavorites,
      fetchData,
      setSortBy,
      clickSearch,
    };
  },
});
</script>

<style lang="scss" scoped>
.compare-list-header {
  display: flex;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 28px;
  height: 46px;

  .number-of-registrations {
    margin-left: 25px;
    font: normal normal normal 14px/20px Noto Sans CJK JP;
    letter-spacing: 0px;
    color: #222222;
  }
}

.mb-50px {
  margin-bottom: 50px;
}
</style>
