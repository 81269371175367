import {
  BizAreaAnalysisResponse,
  BizAreaValue,
  BizAreaAnalysisMapResponse,
  MapBoxFeatures,
  DistanceRankingResponse,
  DistanceRankingValue,
} from "@/features/ShopAnalytics/interfaces/response";
import { AreaListItem } from "@/features/ShopAnalytics/interfaces/component";
import { POINT_STYLE } from "@/commons/enums";

export function processChartData(
  response: BizAreaAnalysisResponse,
  storeName: string
) {
  const tmpArray: (string | number | any)[][] = response.areas.map(
    (data: BizAreaValue, index: number) => {
      return [
        `${(index + 1).toString()}．${data.areaName}`,
        data.visitRatio,
        "#B82F39",
        createBizAreaChartDataTooltipElement(data.areaName, data.visitRatio),
      ];
    }
  );
  tmpArray.unshift([
    "areaName",
    storeName,
    { role: "style" },
    { type: "string", role: "tooltip", p: { html: true } },
  ]);

  return tmpArray;
}

export function processBizAreaShareChartData(
  response: DistanceRankingResponse,
  storeName: string
) {
  const value: DistanceRankingInterface = getDistanceRankingValues(
    response.areas
  );

  const style = POINT_STYLE.RED.default;

  return [
    [
      "distance",
      storeName,
      { type: "string", role: "tooltip", p: { html: true } },
      { type: "string", role: "style" },
    ],
    [
      "~500m",
      value.under500mRate,
      createBizAreaShareChartTooltipElement("~500m", value.under500mRate),
      style,
    ],
    [
      "1km",
      value.under1kmRate,
      createBizAreaShareChartTooltipElement("1km圏内", value.under1kmRate),
      style,
    ],
    [
      "2km",
      value.under2kmRate,
      createBizAreaShareChartTooltipElement("2km圏内", value.under2kmRate),
      style,
    ],
    [
      "3km",
      value.under3kmRate,
      createBizAreaShareChartTooltipElement("3km圏内", value.under3kmRate),
      style,
    ],
    [
      "4km",
      value.under4kmRate,
      createBizAreaShareChartTooltipElement("4km圏内", value.under4kmRate),
      style,
    ],
    [
      "5km",
      value.under5kmRate,
      createBizAreaShareChartTooltipElement("5km圏内", value.under5kmRate),
      style,
    ],
    [
      "6km",
      value.under6kmRate,
      createBizAreaShareChartTooltipElement("6km圏内", value.under6kmRate),
      style,
    ],
    [
      "7km",
      value.under7kmRate,
      createBizAreaShareChartTooltipElement("7km圏内", value.under7kmRate),
      style,
    ],
    [
      "8km",
      value.under8kmRate,
      createBizAreaShareChartTooltipElement("8km圏内", value.under8kmRate),
      style,
    ],
    [
      "9km",
      value.under9kmRate,
      createBizAreaShareChartTooltipElement("9km圏内", value.under9kmRate),
      style,
    ],
    [
      "10km",
      value.under10kmRate,
      createBizAreaShareChartTooltipElement("10km圏内", value.under10kmRate),
      style,
    ],
    [
      "11km",
      value.under11kmRate,
      createBizAreaShareChartTooltipElement("11km圏内", value.under11kmRate),
      style,
    ],
    [
      "12km",
      value.under12kmRate,
      createBizAreaShareChartTooltipElement("12km圏内", value.under12kmRate),
      style,
    ],
    [
      "13km",
      value.under13kmRate,
      createBizAreaShareChartTooltipElement("13km圏内", value.under13kmRate),
      style,
    ],
    [
      "14km",
      value.under14kmRate,
      createBizAreaShareChartTooltipElement("14km圏内", value.under14kmRate),
      style,
    ],
    [
      "15km",
      value.under15kmRate,
      createBizAreaShareChartTooltipElement("15km圏内", value.under15kmRate),
      style,
    ],
    [
      "16km",
      value.under16kmRate,
      createBizAreaShareChartTooltipElement("16km圏内", value.under16kmRate),
      style,
    ],
    [
      "17km",
      value.under17kmRate,
      createBizAreaShareChartTooltipElement("17km圏内", value.under17kmRate),
      style,
    ],
    [
      "18km",
      value.under18kmRate,
      createBizAreaShareChartTooltipElement("18km圏内", value.under18kmRate),
      style,
    ],
    [
      "19km",
      value.under19kmRate,
      createBizAreaShareChartTooltipElement("19km圏内", value.under19kmRate),
      style,
    ],
    [
      "20km",
      value.under20kmRate,
      createBizAreaShareChartTooltipElement("20km圏内", value.under20kmRate),
      style,
    ],
    [
      "20km~",
      value.over20kmRate,
      createBizAreaShareChartTooltipElement("20km~", value.over20kmRate),
      style,
    ],
  ];
}

function createBizAreaShareChartTooltipElement(title: string, value: number) {
  return `
    <div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
      <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div>[店舗からの距離] <b>${title}</b></div>
        <div>[累積比率] <b>
          ${(Math.round(value * 1000) / 10).toFixed(1) + "%"}
        </b></div>
      </div>
    </div>
`;
}

function createBizAreaChartDataTooltipElement(title: string, value: number) {
  return `
    <div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
      <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div>${title}</div>
        <div>[構成比] <b>
          ${(Math.round(value * 1000) / 10).toFixed(1) + "%"}
        </b></div>
      </div>
    </div>
`;
}

export function processBizAreaShareCompareChartData(
  baseStartDate: string,
  baseEndDate: string,
  compareStartDate: string,
  compareEndDate: string,
  response: DistanceRankingResponse,
  compareResponse: DistanceRankingResponse
) {
  const value: DistanceRankingInterface = getDistanceRankingValues(
    response.areas
  );
  const compareValue: DistanceRankingInterface = getDistanceRankingValues(
    compareResponse.areas
  );
  return [
    [
      "distance",
      baseStartDate + " - " + baseEndDate,
      compareStartDate + " - " + compareEndDate,
    ],
    ["~500km", value.under500mRate, compareValue.under500mRate],
    ["1km", value.under1kmRate, compareValue.under1kmRate],
    ["2km", value.under2kmRate, compareValue.under2kmRate],
    ["3km", value.under3kmRate, compareValue.under3kmRate],
    ["4km", value.under4kmRate, compareValue.under4kmRate],
    ["5km", value.under5kmRate, compareValue.under5kmRate],
    ["6km", value.under6kmRate, compareValue.under6kmRate],
    ["7km", value.under7kmRate, compareValue.under7kmRate],
    ["8km", value.under8kmRate, compareValue.under8kmRate],
    ["9km", value.under9kmRate, compareValue.under9kmRate],
    ["10km", value.under10kmRate, compareValue.under10kmRate],
    ["11km", value.under11kmRate, compareValue.under11kmRate],
    ["12km", value.under12kmRate, compareValue.under12kmRate],
    ["13km", value.under13kmRate, compareValue.under13kmRate],
    ["14km", value.under14kmRate, compareValue.under14kmRate],
    ["15km", value.under15kmRate, compareValue.under15kmRate],
    ["16km", value.under16kmRate, compareValue.under16kmRate],
    ["17km", value.under17kmRate, compareValue.under17kmRate],
    ["18km", value.under18kmRate, compareValue.under18kmRate],
    ["19km", value.under19kmRate, compareValue.under19kmRate],
    ["20km", value.under20kmRate, compareValue.under20kmRate],
    ["20km~", value.over20kmRate, compareValue.over20kmRate],
  ];
}

function getDistanceRankingValues(item: DistanceRankingValue[]) {
  const value: DistanceRankingInterface = {
    under500mRate: 0,
    under1kmRate: 0,
    under2kmRate: 0,
    under3kmRate: 0,
    under4kmRate: 0,
    under5kmRate: 0,
    under6kmRate: 0,
    under7kmRate: 0,
    under8kmRate: 0,
    under9kmRate: 0,
    under10kmRate: 0,
    under11kmRate: 0,
    under12kmRate: 0,
    under13kmRate: 0,
    under14kmRate: 0,
    under15kmRate: 0,
    under16kmRate: 0,
    under17kmRate: 0,
    under18kmRate: 0,
    under19kmRate: 0,
    under20kmRate: 0,
    over20kmRate: 0,
  };

  const under500mValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜500m";
    }
  );
  value.under500mRate = under500mValue ? under500mValue.visitRate : 0;

  const under1kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜1km";
    }
  );
  value.under1kmRate = under1kmValue
    ? under1kmValue.visitRate
    : value.under500mRate;

  const under2kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜2km";
    }
  );
  value.under2kmRate = under2kmValue
    ? under2kmValue.visitRate
    : value.under1kmRate;

  const under3kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜3km";
    }
  );
  value.under3kmRate = under3kmValue
    ? under3kmValue.visitRate
    : value.under2kmRate;

  const under4kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜4km";
    }
  );
  value.under4kmRate = under4kmValue
    ? under4kmValue.visitRate
    : value.under3kmRate;

  const under5kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜5km";
    }
  );
  value.under5kmRate = under5kmValue
    ? under5kmValue.visitRate
    : value.under4kmRate;

  const under6kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜6km";
    }
  );
  value.under6kmRate = under6kmValue
    ? under6kmValue.visitRate
    : value.under5kmRate;

  const under7kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜7km";
    }
  );
  value.under7kmRate = under7kmValue
    ? under7kmValue.visitRate
    : value.under6kmRate; 

  const under8kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜8km";
    }
  );
  value.under8kmRate = under8kmValue
    ? under8kmValue.visitRate
    : value.under7kmRate; 

  const under9kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜9km";
    }
  );
  value.under9kmRate = under9kmValue
    ? under9kmValue.visitRate
    : value.under8kmRate; 

  const under10kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜10km";
    }
  );
  value.under10kmRate = under10kmValue
    ? under10kmValue.visitRate
    : value.under9kmRate;

  const under11kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜11km";
    }
  );
  value.under11kmRate = under11kmValue
    ? under11kmValue.visitRate
    : value.under10kmRate;

  const under12kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜12km";
    }
  );
  value.under12kmRate = under12kmValue
    ? under12kmValue.visitRate
    : value.under11kmRate;

  const under13kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜13km";
    }
  );
  value.under13kmRate = under13kmValue
    ? under13kmValue.visitRate
    : value.under12kmRate;

  const under14kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜14km";
    }
  );
  value.under14kmRate = under14kmValue
    ? under14kmValue.visitRate
    : value.under13kmRate;

  const under15kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜15km";
    }
  );
  value.under15kmRate = under15kmValue
    ? under15kmValue.visitRate
    : value.under14kmRate;

  const under16kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜16km";
    }
  );
  value.under16kmRate = under16kmValue
    ? under16kmValue.visitRate
    : value.under15kmRate;

  const under17kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜17km";
    }
  );
  value.under17kmRate = under17kmValue
    ? under17kmValue.visitRate
    : value.under16kmRate;

  const under18kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜18km";
    }
  );
  value.under18kmRate = under18kmValue
    ? under18kmValue.visitRate
    : value.under17kmRate;

  const under19kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜19km";
    }
  );
  value.under19kmRate = under19kmValue
    ? under19kmValue.visitRate
    : value.under18kmRate;

  const under20kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "〜20km";
    }
  );
  value.under20kmRate = under20kmValue
    ? under20kmValue.visitRate
    : value.under19kmRate;

  const over20kmValue: DistanceRankingValue | undefined = item.find(
    (data: DistanceRankingValue) => {
      return data.distanceRange === "20km〜";
    }
  );
  value.over20kmRate = over20kmValue
    ? over20kmValue.visitRate
    : value.under20kmRate;
  return value;
}

interface DistanceRankingInterface {
  under500mRate: number;
  under1kmRate: number;
  under2kmRate: number;
  under3kmRate: number;
  under4kmRate: number;
  under5kmRate: number;
  under6kmRate: number;
  under7kmRate: number;
  under8kmRate: number;
  under9kmRate: number;
  under10kmRate: number;
  under11kmRate: number;
  under12kmRate: number;
  under13kmRate: number;
  under14kmRate: number;
  under15kmRate: number;
  under16kmRate: number;
  under17kmRate: number;
  under18kmRate: number;
  under19kmRate: number;
  under20kmRate: number;
  over20kmRate: number;
}

export function getBizAreaMaxRate(response: BizAreaAnalysisMapResponse) {
  let maxRate = 0;
  response.features.forEach((data: MapBoxFeatures) => {
    if (data.properties.visitRatio >= maxRate)
      maxRate = data.properties.visitRatio;
  });
  return maxRate;
}

export function getMapAreaList(response: BizAreaAnalysisMapResponse) {
  const array: AreaListItem[] = [];
  response.features.forEach((item: MapBoxFeatures) => {
    array.push({
      areaId: item.properties.areaId,
      // TODO: エリア名は別タスクにて追加されるのでその時に追加する
      areaName: "",
    });
  });
  return array;
}

export function isMapNoData(response: BizAreaAnalysisMapResponse) {
  return response.features
    .filter(
      (feature: MapBoxFeatures) =>
        typeof feature.properties.visitRatio === "number"
    )
    .every((feature: MapBoxFeatures) => feature.properties.visitRatio === 0);
}

// 商圏マップ取得でエリア名を取得するようになったら不要
export function getRankingAreaList(response: BizAreaAnalysisResponse) {
  const array: AreaListItem[] = [];
  response.areas.forEach((item: BizAreaValue, index: number) => {
    array.push({
      areaId: item.areaId,
      areaName: `${(index + 1).toString()}．${item.areaName}`,
    });
  });
  return array;
}

export function isRankingNoData(response: BizAreaAnalysisResponse) {
  return response.areas.every((area: BizAreaValue) => area.visitRatio === 0);
}
