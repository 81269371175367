<template>
  <div class="regional_map_popup">
    <img
      class="close"
      src="@/assets/svg/close-small.svg"
      @click="$emit('closePopup')"
    >
    <div class="popup_content">
      <div class="popup_name">
        {{ areaName }}
      </div>
      <br>
      <div class="popup_description">
        <div>
          [最大シェア]
          <span :style="'color: ' + storeColor">● </span>
          {{ storeName }}
        </div>
        <div>
          [シェア率]
          <b>{{ share ? (Math.round(share * 1000) / 10).toFixed(1) : 0.0 }}%</b>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { STORE_TYPE } from "@/commons/enums";

export default defineComponent({
  name: "BizAreaMapPopup",
  props: {
    areaName: { type: String },
    storeName: { type: String },
    storeColor: { type: String },
    share: { type: Number },
  },
  data() {
    return {
      storeTypes: STORE_TYPE,
    };
  },
});
</script>

<style scoped lang="scss">
.regional_map_popup {
  width: 220px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  border-radius: 3.5px;
}
.close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.popup_content {
  min-height: 68px;
  padding-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
}
.popup_name {
  font: normal normal bold 13px/19px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #333333;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.popup_description {
  font: normal normal normal 12px/20px Noto Sans CJK JP;
  letter-spacing: 0px;
  margin-bottom: 14.5px;
}
</style>
