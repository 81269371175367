<template>
  <v-container
    class="view-container"
    fluid
  >
    <div class="view-title">
      <div class="title-text">
        <b>ユーザーの新規追加</b>
      </div>
      <div class="title-sub">
        <router-link
          class="user_setting_link"
          :to="{ name: 'UserSetting' }"
        >
          ユーザー設定 TOPへ
        </router-link>
      </div>
    </div>
    <div class="view-content">
      <div class="description">
        * 印は必須入力事項です。
      </div>
      <form @submit.prevent="register">
        <div class="form-row">
          <div class="form-title">
            <b>部署名</b>
          </div>
          <custom-input
            v-model="department"
            class="form-input"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）ビーコンバンク事業部"
          />
        </div>
        <div class="form-row">
          <div class="form-title">
            <b>氏名*</b>
          </div>
          <custom-input
            v-model="name"
            class="form-input"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）宇根利 太郎"
          />
        </div>
        <div class="form-row">
          <div class="form-title">
            <b>メールアドレス*（一度登録すると変更できません）</b>
          </div>
          <custom-input
            v-model="mail"
            class="form-input"
            type="email"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）username@unerry.co.jp"
          />
        </div>
        <div class="form-footer">
          <custom-button
            class="button"
            variant="primary"
            width="220px"
            height="46px"
          >
            招待メールを送信
          </custom-button>
        </div>
      </form>
    </div>
    <div class="divider" />
    <div class="view-footer">
      あらかじめ、shopper-mieru.jpドメインのメールが受け取れるように設定をお願いします。
    </div>
    <contract-conflict-dialog v-model="dialog" />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ContractConflictDialog from "@/features/UserSetting/components/Top/ContractConflictDialog.vue";
import * as notify from "@/plugins/notification";
import { createUser } from "@/features/UserSetting/axios";
import { AxiosError } from "axios";

export default defineComponent({
  name: "UserCreateView",
  components: { ContractConflictDialog },
  data() {
    return {
      department: "" as string | undefined,
      name: "" as string | undefined,
      mail: "" as string | undefined,
      dialog: false,
    };
  },
  methods: {
    register() {
      let validate = false;
      if (typeof this.name === "undefined" || !this.name) {
        notify.notifyErrorMessage("氏名を入力してください。");
        validate = true;
      }
      if (typeof this.mail === "undefined" || !this.mail) {
        notify.notifyErrorMessage("メールアドレスを入力してください。");
        validate = true;
      } else {
        const regex = new RegExp(
          "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*.)+[a-zA-Z]{2,}$"
        );
        if (!regex.test(String(this.mail))) {
          notify.notifyErrorMessage("メールアドレスの形式が正しくありません。");
          validate = true;
        }
      }
      if (validate) return;

      createUser(String(this.name), String(this.mail), this.department)
        .then(() => {
          notify.notifySuccessMessage("招待メールを送信しました。");
          this.$router.push({ name: "UserSetting" });
        })
        .catch((err: AxiosError) => {
          // 契約競合
          if (
            err.response?.status === 400 &&
            (err.response?.data as { errors: string[] }).errors.includes(
              "CONTRACT_CONFLICT"
            )
          ) {
            this.dialog = true;
          } else {
            notify.notifyErrorMessage("ユーザー登録に失敗しました。");
          }
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  min-width: 1180px;
  margin-top: 30px !important;
  background-color: #ffffff;
  overflow: hidden;
  padding: 40px 60px !important;
  box-shadow: 0px 0px 3px #00000029;
}
.view-title {
  height: 35px;
  display: flex;
  align-items: center;
  .title-text {
    min-width: 925px;
    width: 100%;
    font-size: 24px;
    overflow-wrap: break-word;
    float: left;
  }
  .title-sub {
    display: flex;
    min-width: 120px;
    font-size: 13px;
    align-items: center;
    float: right;
  }
}
.view-content {
  margin: 40px 0px;
  text-align: left;
  .form-row {
    margin-bottom: 30px;
  }
  .description {
    color: #222222;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .form-title {
    color: #222222;
    font-size: 15px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }
  .form-input {
    padding: 12px 17px;
    font: normal normal normal 15px/20px Noto Sans CJK JP;
  }
  .form-footer {
    text-align: end;
  }
}
.divider {
  border: 1px solid #eee;
  margin: 48px 0px 30px 0px;
}
.view-footer {
  color: #222222;
  font: normal normal normal 13px/26px Noto Sans CJK JP;
  letter-spacing: 0px;
  text-align: left;
}
.user_setting_link {
  text-decoration-line: none;
}
.button {
  font-size: 15px;
}
</style>
