<template>
  <v-dialog
    v-model="show_dialog"
    width="76%"
    scrollable
    @click:outside="close"
  >
    <div class="inner-container">
      <div class="title">
        ショッパーみえーる 利用規約
      </div>
      <div class="enactment">
        <p>2021年09月15日 制定</p>
        <p>2022年12月20日 改訂</p>
      </div>
      <div class="content">
        <section>
          <p>
            ショッパーみえーる
            利用規約（以下、「本規約」という。）は、株式会社unerry（以下、「当社」という。）が提供する『消費財・小売業界向けショッパーみえーる』（オプションサービスを含む。また、理由のいかんにかかわらず、本サービスの名称または内容が変更された場合には当該変更後のサービスを含むものとし、以下「本サービス」という。）の利用に関して、利用にかかる条件を定めるものです。
          </p>
        </section>
        <section>
          <h2>第１章 総則</h2>
          <section>
            <h3>第１条（適用）</h3>
            <p>
              本規約は、本サービスの提供条件および本サービスの利用に関する当社と本サービスの契約者との権利義務関係を定めることを目的とし、当社と本サービスの契約者との本サービスの利用に関わる一切の関係に適用されます。
            </p>
          </section>
          <section>
            <h3>第２条（定義）</h3>
            <ol>
              <li>
                <span>「契約者」とは、本規約に基づく本契約を当社と締結し、本サービスの提供を受ける会社その他の法人等の団体、個人をいいます。</span>
              </li>
              <li>
                <span>「ユーザー」とは、本契約に基づいて契約者が承認し、当該承認に基づいて本サービスを利用することができる者をいいます。</span>
              </li>
              <li>
                <span>「契約者等」とは、契約者およびユーザーをいいます。</span>
              </li>
              <li>
                <span>「本契約」とは、本規約に基づいて、当社と契約者との間で締結される本サービスの利用契約をいいます。</span>
              </li>
              <li>
                <span>「登録情報」とは、契約者が当社に登録する名称、住所、電話番号、電子メールアドレスおよびパスワードその他当社が契約者に本サービスを提供するために必要な情報をいいます。</span>
              </li>
              <li>
                <span>「端末機器」とは、本サービスを利用するために必要となるコンピュータ、スマートフォン、タブレット等のハードウェアをいいます。</span>
              </li>
              <li>
                <span>「本ソフトウェア」とは、本サービスの機能の一部を利用するためにインストールが必要となるソフトウェアをいいます。</span>
              </li>
              <li>
                <span>「本サービス用設備」とは、本サービスを提供するにあたり、当社が設置するコンピュータ、電気通信設備その他の機器およびソフトウェアをいいます。</span>
              </li>
              <li>
                <span>「消費税等」とは、消費税法および同法に関連する法令の規定に基づき課税される消費税の額並びに地方税法および同法に関する法令の規定に基づき課税される地方消費税の額その他契約者が支払に際して負担すべき公租公課をいいます。</span>
              </li>
              <li>
                <span>「ID」とは、契約者等とその他の者を識別するために用いられる符号をいいます。</span>
              </li>
              <li>
                <span>「パスワード」とは、IDとの組み合わせにより、契約者等とその他の者を識別するために用いられる符号をいいます。</span>
              </li>
              <li>
                <span>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第３条（本規約について）</h3>
            <ol>
              <li>
                <span>当社は、本規約に基づいて、本契約の内容にしたがって本サービスを提供し、契約者は本規約および本契約の内容にしたがってこれを利用するとともに、ユーザーに本契約の内容を遵守させるものとします。</span>
              </li>
              <li>
                <span>当社は、当社が提供する個別のサービスにおいて個別の規約やガイドライン（以下総称して「個別規約」という。）を定めることがあります。個別規約は、名称のいかんにかかわらず、本規約の一部を構成するものとします。</span>
              </li>
              <li>
                <span>本規約と個別規約が矛盾する場合には、個別規約の内容が優先するものとします。なお、個別規約に定めのない事項については本規約が適用されるものとします。</span>
              </li>
              <li>
                <span>本規約および個別規約（以下総称して「本規約等」という。）の一部の規定の全部または一部が法令等に基づいて無効であると判断される場合であっても、当該無効部分以外の部分および本規約等のその他の規定の有効性に影響がないものとします。なお、本規約等の一部が特定の契約者との間で無効とされるかまたは取り消された場合においても、本規約等はその他の契約者との関係においては有効であるものとします。</span>
              </li>
              <li>
                <span>当社が本規約等上の権利を行使しない場合においても、当社が当該権利を放棄したことを意味するものではありません。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第４条（通知）</h3>
            <ol>
              <li>
                <span>当社からの契約者への通知は、本契約に特段の定めのない限り、当社が運営するWebサイトに掲載する方法または登録情報の宛先に対して電子メールを送信する方法、その他当社が適切と判断する方法により行います。</span>
              </li>
              <li>
                <span>前項の規定に基づき、当社から契約者への通知を前項の方法により行う場合には、契約者に対する当該通知は、それぞれ前項のWebサイトに掲載がされた時点または電子メールを送信した時点、その他当社が別途定める時点から効力を生じるものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第５条（変更）</h3>
            <ol>
              <li>
                <span>当社は、契約者の事前の承諾を得ることなく、当社の判断において、本規約等をいつでも変更できるものとします。本規約等が変更された後の本サービスの提供条件は、当社が明示的に別段の措置を講じた場合をのぞき、変更後の本規約によるものとします。</span>
              </li>
              <li>
                <span>当社は前項の変更を行う場合には、本規約等の変更日までに、変更後の本規約等の内容を、当社が運営するWebサイトに掲載する方法または当社の定める方法により契約者に通知するものとします。ただし、変更が軽微で契約者に特段の不利益を及ぼすものではない場合には、この限りではありません。</span>
              </li>
              <li>
                <span>変更後の本規約等は、当社が別段の定めをした場合を除き、当社が運営するWebサイトに掲載された変更後の本規約等に記載された変更日時点より効力を生じるものとします。契約者が、本規約等の変更の効力が生じた後に本サービスを利用した場合には、変更後の本規約等のすべての内容に同意したものとみなされます。</span>
              </li>
            </ol>
          </section>
        </section>
        <section>
          <h2>第２章 本サービス</h2>
          <section>
            <h3>第６条（本サービスの内容）</h3>
            <ol>
              <li>
                <span>本サービスは、契約者またはユーザーがユーザーIDおよびパスワードを利用してログインするダッシュボード画面を通じて、弊社が指定した店舗等の情報および弊社が指定した店舗等にかかる来店情報等を提供するものです。</span>
              </li>
              <li>
                <span>本サービスの提供内容、提供範囲、提供方法、利用時間帯その他の諸条件は、サービス仕様書に記載のとおりとします。なお、当社は、本サービスの内容及び機能等を、当社の判断に基づき、事前予告なしに随時追加、変更又は削除等する場合があります。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第7条（サービスレベル）</h3>
            <ol>
              <li>
                <span>契約者は、本サービスにおける店舗等の情報および店舗等にかかる来店情報については、あくまで当社による推定値であり、店舗等に入店した者の数値を実測等したものではないこと、また、ダッシュボード上の地図に表示される推定居住地区情報等についても、当社による推定データであるとともにプライバシー保護等の観点から当社が任意に一定の変更・省略等を行うものであることを確認します。</span>
              </li>
              <li>
                <span>当社は、サービスの改善のため、各指標について、契約者の事前の承諾を得ることなく、当社の判断において変更できるものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第８条（サポート）</h3>
            <p>
              当社は、本サービスに関する契約者からの問い合わせについて、電話、電子メールなど当社の定める方法により回答をするものとします。
            </p>
          </section>
          <section>
            <h3>第９条（本契約の成立）</h3>
            <ol>
              <li>
                <span>本サービスの利用申込者（以下「申込者」という。）は、当社が定める方法により、本サービス利用のための申し込み（以下「申込」という。）を行うものとします。</span>
              </li>
              <li>
                <span>本契約は、当社が申込に対して当社が定める方法により承諾の通知を発信したときに成立するものとします。なお、申込者は本規約の内容を承諾の上、当該申込を行うものとします。申込者が申込を行った時点で、当社は、申込者が本規約の内容を承諾しているものとします。申込者が本条の規定に基づいて申込をする場合には、当該申込は、本契約の締結に関し、申込者である会社、その他の法人等の団体または個人の適法かつ正当な授権に基づくものとみなします。</span>
              </li>
              <li>
                <span>当社は前各項その他本規約等の規定にかかわらず、申込者が次の各号のいずれかに該当する場合には、申込を承諾せずまたは承諾を保留することができます。
                  <ol>
                    <li>
                      <span>申込者が当社に対して申込時に提供する情報に関して、全部または一部につき虚偽、誤記または記載漏れがあった場合</span>
                    </li>
                    <li>
                      <span>未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得てない場合</span>
                    </li>
                    <li><span>申込者が実在しない場合</span></li>
                    <li>
                      <span>当社が申込者に送信した電子メールが到達しなかった場合</span>
                    </li>
                    <li>
                      <span>申込者が過去に利用料の支払いを遅延または不正に免れようとしたことがあった場合</span>
                    </li>
                    <li>
                      <span>申込者が本契約に違反した者またはその関係者であると当社が判断した場合</span>
                    </li>
                    <li>
                      <span>申込者の本サービスの利用目的が、本サービスの評価、解析その他本来の目的と異なるものであると当社が判断した場合</span>
                    </li>
                    <li>
                      <span>当社が運営する本サービス以外のサービスにおいて、当該サービスにおける規約等に違反したことがあった場合</span>
                    </li>
                    <li>
                      <span>申込者が第２３条第１項各号のいずれかに該当または同条第２項各号の規定に該当する行為を行った場合</span>
                    </li>
                    <li>
                      <span>その他、当社が申込者を適当でないと判断した場合</span>
                    </li>
                  </ol></span>
              </li>

              <li>
                <span>前項の規定に基づいて、当社が申込を承諾しないまたは留保する場合には、その旨を申込者に通知するものとすします。ただし、当社は、承諾をしなかったことまたは承諾を留保したことによる一切の責任を負わないこととします。</span>
              </li>
              <li>
                <span>契約者は、第２４条の規定による場合を除き、本条第２項の規定に基づいて本契約が成立した時点以降は、申込のキャンセルをできないものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１０条（本サービスの利用料、支払い方法、契約期間）</h3>
            <ol>
              <li>
                <span>本サービスの利用料（以下「利用料」という。）は、本サービスの料金表に定めるものとします。ただし、当社は、30日以上前に契約者に通知することにより、契約者の事前の承諾を得ずに、当社の判断において、利用料を改定することができます（この利用料の改定の場合においては、本規約の他の規定にかかわらず、契約者は通知の受領後2週間の間、当社に通知することにより、追加の費用負担なく中途解約を行うことができるものとし、既払の利用料については、年365日の日割り計算により精算されるものとします。）。</span>
              </li>
              <li>
                <span>契約者は本契約に基づき、利用期間における本サービス利用の対価として前項の料金表に定める利用料または当社が提供する別途見積書、請求書に記載される利用料をこれにかかる消費税とともに、当社が指定した支払い方法により支払うものとします。なお、銀行振込による場合、振込手数料は契約者の負担とします。</span>
              </li>
              <li>
                <span>利用開始日は、申込後、当社が別途通知するものとします。申込日から利用開始日までは無料で本サービスを利用することができ、本契約の開始日および終了日にかかわらず、本規約等で明示的に規定する場合を除いて日割り計算されません。</span>
              </li>
              <li>
                <span>契約期間は利用開始日から1年契約とし（その有効期間を「本期間」といいます。）、途中解約の場合、本規約等で明示的に規定する場合を除いて利用料は返金されません。</span>
              </li>
              <li>
                <span>利用料の支払期限は、次の各号に掲げるとおりとします。
                  <ol>
                    <li>
                      <span>初期費用は、利用開始日が属する月の翌月末日までに一括で支払うものとします。
                      </span>
                    </li>
                    <li>
                      <span>月額費用およびオプション利用料金は、利用月の翌月末日までに支払うものとし、初月の利用料については日割り計算するものとします。
                      </span>
                    </li>
                    <li>
                      <span>その他料金については、当社が指定した期日までに利用料を支払うものとします。
                      </span>
                    </li>
                  </ol></span>
              </li>
              <li>
                <span>契約者が所定の支払期日までに利用料を支払わない場合、契約者は、支払期日の翌日から完済にいたるまで年利１４.６％の割合による遅延損害金を当社に支払うものとします。</span>
              </li>
              <li>
                <span>本契約の終了原因のいかんにかかわらず、本契約が本期間の満了前に終了した場合であっても、本規約等で明示的に規定する場合を除いて利用料は返金されません。</span>
              </li>
              <li>
                <span>本期間において、本サービスの提供の中断、停止その他の事由により本サービスを利用することができない事態が生じたときでも、本規約等で明示的に規定する場合を除いて利用料は返金されません。</span>
              </li>
              <li>
                <span>利用料の支払いに関して、契約者と金融機関との間で紛争が発生した場合には、契約者が自らの責任と負担で解決するものとし、当社は一切の責任を負わないものとします。</span>
              </li>
              <li>
                <span>利用料の支払いが発生しない本契約の有効期間は、第９条に規定する本契約の成立日から契約開始日までの間とします。</span>
              </li>
              <li>
                <span>利用料の支払いが発生する本契約の有効期間は、契約者による本サービスの契約開始日から起算して1年間とします。</span>
              </li>
              <li>
                <span>本期間満了日の一か月前までに、契約者から当社に対して、当社が指定する解約の方法で申し出がなかった場合、本契約は、本期間満了日の翌月の1日を契約更新日として同内容で１年間自動的に更新がされるものとします。またこれは以後も同様とします。なお、本項の規定に基づいて本契約の更新がなされた場合において、契約者が本契約を解除しようとするときには、第２４条の規定によるものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１１条（登録情報の変更）</h3>
            <ol>
              <li>
                <span>登録情報に変更があった場合には、契約者は当社の定める方法により当該変更情報を速やかに当社に通知するものとします。当社は当該変更事項を証明する資料の提出を契約者に求めることができ、契約者はこれに応じるものとします。</span>
              </li>
              <li>
                <span>前項の通知がないことが原因で、当社からの通知等の遅延または到達しなかった場合には、当該通知等は通常到達すべき時に到達したものとみなされるとともに、当社はこれらの事由により契約者に発生したいかなる損害についても、一切の責任を負いません。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１２条（ユーザーIDおよびパスワード）</h3>
            <ol>
              <li>
                <span>ユーザーIDおよびパスワード等は、当社が定める方法および使用条件に基づき、契約者が本契約範囲内でユーザーを定め、契約者がユーザーに付与するものとします。また、契約者はユーザーによる本サービスの利用について一切の責任を負うものとします。</span>
              </li>
              <li>
                <span>契約者は自己の責任において、本サービスに関するユーザーIDおよびパスワード等を不正利用されないように、適切に管理および保管するものとし、これをいかなる場合であっても第三者に利用させ、または開示、貸与、譲渡、名義変更、売買等をしてはならないものとします。</span>
              </li>
              <li>
                <span>契約者はユーザーIDおよびパスワード等が不正利用されているまたは不正に使用される可能性がある場合には、速やかに当社に連絡し、当社の指示に従うものとします。</span>
              </li>
              <li>
                <span>ユーザーIDおよびパスワード等の管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は契約者が負うものとし、当社は一切の責任を負いません。</span>
              </li>
              <li>
                <span>第三者が契約者等のユーザーIDおよびパスワード等を用いて本サービスを利用した場合には、当該利用は契約者等による利用とみなされるものとし、契約者等は当該利用について利用料の支払いまたはその他の債務一切を負担するものとします。また、当該利用により当社が損害を負った場合には、契約者等は当該損害を賠償するものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１３条（自己責任の原則）</h3>
            <ol>
              <li>
                <span>契約者およびユーザーが使用する端末機器、本サービスに接続する電気通信回線、その他利用環境については、契約者が自己の負担と責任において確保および維持するものとし、当社は一切の責任を負いません。</span>
              </li>
              <li>
                <span>契約者は本サービスの利用に伴い、自己（ユーザーを含む。以下同じ。）の責に帰すべき事由により第三者に対して損害を与えた場合または第三者からクレーム等の請求がなされた場合には、自己の責任と費用をもってこれに対処し、解決するものとします。契約者が本サービスの利用に伴い、第三者から損害を被った場合または第三者に対してクレーム等の請求を行う場合においても同様とします。</span>
              </li>
              <li>
                <span>契約者は本規約に限らず契約者が自己の責に帰すべき事由により当社に損害を与えた場合、当社に対して当該損害の賠償を行うものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１４条（本サービスの中断および廃止）</h3>
            <ol>
              <li>
                <span>当社は、以下の各号に記載するいずれかに該当する場合には、契約者に事前に通知することなく、一時的に本サービスの全部または一部の提供を中断することができるものとします。
                  <ol>
                    <li>
                      <span>本サービスに係るコンピュータシステム、サーバー、設備の保守および点検を定期的にまたは緊急に行う場合
                      </span>
                    </li>
                    <li>
                      <span>本サービスに係るコンピュータシステム、サーバー、設備、通信回線等が事故により停止した場合
                      </span>
                    </li>
                    <li>
                      <span>地震、落雷、火災、噴火、洪水、津波、停電および天災地変などの不可抗力により本サービスの提供ができなくなった場合
                      </span>
                    </li>
                    <li>
                      <span>戦争、動乱、暴動、争乱、労働争議等により本サービスの提供ができなくなった場合
                      </span>
                    </li>
                    <li>
                      <span>その他、当社が停止または中断が必要であると判断した場合
                      </span>
                    </li>
                  </ol></span>
              </li>
              <li>
                <span>前項に掲げる事由により、本サービスの全部または一部の提供に遅延または中断が発生し、契約者が本サービスを利用できなかったとしても、当社は、これに起因して契約者およびその他の第三者が被った損害に対して、一切の責任を負いません。</span>
              </li>
              <li>
                <span>当社は事前の承諾を得ることなく、当社の判断において、本サービスの全部または一部をいつでも廃止できる権利を有します。</span>
              </li>
              <li>
                <span>本サービスの全部または一部を廃止する場合には、当社は廃止日の２か月以上前に、契約者に対して通知します。ただし、当社において商業的に合理的な努力をもってしても予期できない事由または法令および規則の制定および改廃もしくは天災地変等のやむをえない事由がある場合には、この限りではありません。</span>
              </li>
              <li>
                <span>本条の規定に基づいて、本サービスの全部または一部が廃止された場合には、当社は、当該廃止による結果について、一切の責任を負いません。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１５条（保証の否認および免責）</h3>
            <ol>
              <li>
                <span>当社は、本規約等で明示的に規定する場合を除き、明示的か否かまたは法令もしくはそれ以外に基づくものであるかを問わず、契約者が本サービスに期待する機能、本サービスの正確性、商品的価値、継続性、有用性、完全性および確実性を含む信頼性、利用可能性、セキュリティ保護性、無エラー性、無ウイルス性、品質満足度ならびに契約者への特定目的への適合性を含むいかなる種類の保証も行わず、これらに起因して契約者等およびその他の第三者が被った被害に関して、一切の責任を負いません。</span>
              </li>
              <li>
                <span>当社は、次の各号に掲げる事由によって契約者等およびその他の第三者に発生した損害については、債務不履行責任、不法行為責任、その他の法律上の請求原因のいかんにかかわらず、一切の責任を負いません。
                  <ol>
                    <li>
                      <span>戦争、動乱、暴動、争乱、労働争議、地震、落雷、火災、水害、禁輸措置等の政府機関の行為、インターネットの利用不能または不安定、停電またはその他の不可抗力が発生した場合
                      </span>
                    </li>
                    <li>
                      <span>本サービスのデータセンター等の設備に障害が発生した場合
                      </span>
                    </li>
                    <li>
                      <span>本サービスの定期的な保守および点検または緊急の保守および点検作業を行った場合
                      </span>
                    </li>
                    <li>
                      <span>当社が第三者から導入をしているコンピュータウイルス対策ソフトにおいて、当該第三者からウイルスパターン、ウイルス定義ファイル等を提供されていない種類のコンピュータウイルスの本サービス用設備への侵入がされた場合
                      </span>
                    </li>
                    <li>
                      <span>善良な管理者の注意をもってしても防げない本サービス用設備への第三者による不正アクセスまたはアタック、通信経路上での傍受が発生した場合
                      </span>
                    </li>
                    <li>
                      <span>電気通信事業者の提供するインターネット接続サービスなどの電気通信役務の不具合により損害が発生した場合
                      </span>
                    </li>
                    <li>
                      <span>情報端末他契約者等およびユーザーが利用するハードウェアの品質不良、スペック不足、その他ハードウェア同士の相性等の不具合が発生した場合
                      </span>
                    </li>
                    <li>
                      <span>情報端末のOSの他契約者等およびユーザーが利用するソフトウェアの品質不良、スペック不足、その他ソフトウェア同士の相性等の不具合が発生した場合
                      </span>
                    </li>
                    <li>
                      <span>裁判所判決、決定もしくは命令または法令に基づく強制的な処分
                      </span>
                    </li>
                    <li><span>その他当社の責に帰すべき事由以外の原因</span></li>
                  </ol></span>
              </li>
              <li>
                <span>契約者等およびユーザーは、サービスの利用規約および運用方針の変更に伴い、本サービスの全部または一部の利用制限が発生する可能性について、あらかじめこれを了承するものとします。当社は当該制限について一切の責任を負いません。</span>
              </li>
              <li>
                <span>当社は本サービスがすべての端末機器に対応していることを保証するものではなく、本サービスの利用に供する端末機器のOSバージョンアップ等に伴い、本サービスの動作等に不具合が発生する可能性について、契約者等およびユーザーは、あらかじめこれを了承するものとします。なお当社は、当該不具合が発生した場合、当社が行うプログラムの修正等によって当該不具合が解消されることを保証するものではなく、当社は当該不具合に起因して、契約者等およびその他の第三者が被る損害について、当社は一切の責任を負いません。</span>
              </li>
              <li>
                <span>契約者が本サービスに送信したメッセージまたは情報の削除または消失、契約者の登録の抹消、本サービスの利用による登録データの消失、その他本サービスに関して契約者等およびユーザーその他の第三者が被る損害（営業状の利益の損失、業務の中断、営業情報の喪失などの損害を含む。）につき賠償する責任を当社は負いません。</span>
              </li>
              <li>
                <span>何らかの理由により、当社が契約者に対して損害賠償責任を負うのは、当社に故意または重過失がある場合に限るものとし、また、当社に故意がある場合を除き、契約者の損害につき、損害発生時点からさかのぼって１年間に契約者が当社に本サービス利用料として支払った金額の総額を当社による賠償の上限範囲とし、当社は、この総額を超えて契約者に対して賠償する責任を負わないものとします。また、付随的損害、間接的損害、特別損害、将来の損害および逸失利益にかかる損害に対しての賠償は、当社は一切の責任を負いません。なお、本項の規定は、債務不履行責任、不法行為責任、その他法律上の請求原因のいかんにかかわらず、当社が契約者に対して負うすべての損害賠償責任に適用されるものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１６条（禁止事項）</h3>
            <ol>
              <li>
                <span>契約者等およびユーザーは本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為を行わないものとします。
                  <ol>
                    <li>
                      <span>法令に違反する行為または犯罪行為に関連する行為ならびに法令に違反する可能性のある行為
                      </span>
                    </li>
                    <li>
                      <span>当社、本サービスの他のユーザーまたはその他の第三者に対する詐欺行為または脅迫行為
                      </span>
                    </li>
                    <li><span>公序良俗に反する行為</span></li>
                    <li>
                      <span>裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為
                      </span>
                    </li>
                    <li>
                      <span>当社、本サービスの他のユーザーまたはその他の第三者の知的財産権、肖像権、プライバシー権等の人格権およびパブリシティー権、その他の権利または利益を侵害する行為ならびに本サービスを利用して個人を特定する目的で行うあらゆる行為
                      </span>
                    </li>
                    <li>
                      <span>他の契約者による本サービスの利用を妨害する行為</span>
                    </li>
                    <li>
                      <span>本サービスに損害を与え、これを使用不能にし、これに過度な負担を与え、またはこれを害する方法で本サービスにアクセスする行為
                      </span>
                    </li>
                    <li>
                      <span>本サービスの運営または提供を妨害する行為</span>
                    </li>
                    <li>
                      <span>本サービスを構成するハードウェアまたはソフトウェアへの不正アクセス行為、クラッキング行為その他設備等に支障を与える等の行為
                      </span>
                    </li>
                    <li>
                      <span>本サービスを修正、変更、改変または逆コンパイル、逆アセンブル、リバースエンジニアリングその他の方法により、本サービスおよび本ソフトウェアのソースコードの解析を試みる行為
                      </span>
                    </li>
                    <li>
                      <span>第三者を差別もしくは誹謗中傷し、またはその名誉もしくは信用を毀損する行為
                      </span>
                    </li>
                    <li><span>第三者になりすます行為</span></li>
                    <li>
                      <span>本サービスの他のユーザーのユーザーIDまたはパスワードを利用する行為またはその入手を試みる行為
                      </span>
                    </li>
                    <li><span>本サービスの他のユーザーの情報収集</span></li>
                    <li>
                      <span>本サービスのシステムに対して、許可されないアクセスを試みる行為
                      </span>
                    </li>
                    <li>
                      <span>本サービスの内容や本サービスにより利用しうる情報を改ざんもしくは完全性を損なう行為
                      </span>
                    </li>
                    <li>
                      <span>第三者の設備等または本サービス用設備の利用もしくは運営に支障を与える行為
                      </span>
                    </li>
                    <li>
                      <span>本サービスの品質、性能もしくは機能の測定その他のベンチマーク、競合する製品もしくはサービスを開発する目的、または本サービスの特徴もしくは機能を模倣しまたは複製する目的で、本サービスにアクセスする行為
                      </span>
                    </li>
                    <li><span>反社会的勢力等への利益供与</span></li>
                    <li>
                      <span>当社の事前の承諾なくして、本サービスのID等を複数の法人または団体で共有する等の、自己のID等を利用して本サービスを第三者（グループ企業を含みますが、これに限らない。）に利用させる行為
                      </span>
                    </li>
                    <li>
                      <span>複数の者との間でのIDの共有等を推認させる電子メールアドレスをID等および登録情報に使用する行為
                      </span>
                    </li>
                    <li><span>その他当社が不適切と判断する行為</span></li>
                  </ol></span>
              </li>
              <li>
                <span>契約者等は、前各号のいずれかに該当する行為がなされたことを知った場合または該当する行為がなされる可能性があると判断した場合には、直ちに当社に通知をするものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１７条（権利帰属）</h3>
            <ol>
              <li>
                <span>当社は、本サービスの提供終了後であっても、契約者の本サービスの利用内容を取り扱い事例として、当社のWebサイト、動画、情報誌その他各種媒体（電磁的方法であるかを問わない。）に対して、無償で掲載（契約者のロゴおよび商標等の掲載を含む。）することができ、契約者はこれを許諾するものとします。</span>
              </li>
              <li>
                <span>本サービスにかかる知的財産権は、すべて当社に帰属するものであり、契約者は、本契約に定める範囲において、その利用が許諾されるものとします。契約者およびユーザーは、本期間終了までの間に限り、本サービスの利用結果を自社内でのみ利用することができるものとし、当社による承諾をなくして第三者に対して、本サービスの利用結果の全部または一部を開示することができないものとします。なお、当社による承諾を得て第三者に本サービスの利用結果を開示する場合においては、提供する数値等が当社による推定値であることを明示しなければならないものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第１８条（データのバックアップ）</h3>
            <ol>
              <li>
                <span>当社は、本サービスにより提供したデータの保存義務を負うものではなく、契約者は本サービスの利用に関連して入力、提供または伝送するデータ等について、必要な情報は自己の責任で保存をしておくものとします。</span>
              </li>
              <li>
                <span>前項の規定にかかわらず、本サービスの提供等に必要な場合には、当社は、本サービスの提供等のためのバックアップを目的として、必要な範囲において、本サービス用設備内のサーバに保存されたデータおよびデータベースを複製することができます。</span>
              </li>
            </ol>
          </section>
        </section>
        <section>
          <h2>第３章 一般条項</h2>
          <section>
            <h3>第１９条（秘密保持）</h3>
            <ol>
              <li>
                <span>契約者および当社は、本契約に関して相手方から秘密である旨を明示して開示された相手方の技術および営業等に関する情報（以下「秘密情報」という。）を、相手方の事前の承諾なく第三者（ただし、契約者の秘密情報については、委託先を除く第三者とする。）に開示してはならないものとします。ただし、次の各号に掲げるものについては、秘密情報には該当しないものとします。
                  <ol>
                    <li>
                      <span>相手方から開示された時点において秘密情報の開示を受ける当事者（以下「受領当事者」という。）が既に有していた情報
                      </span>
                    </li>
                    <li>
                      <span>相手方から開示された時点において既に公知の情報</span>
                    </li>
                    <li>
                      <span>相手方から開示された後に受領当事者の責によらない事由によって公知となった情報
                      </span>
                    </li>
                    <li>
                      <span>相手方から開示された後に受領当事者が第三者から秘密保持義務を負うことなく適法に入手した情報
                      </span>
                    </li>
                    <li>
                      <span>秘密情報を用いることなく受領当事者が独自に開発した情報
                      </span>
                    </li>
                  </ol></span>
              </li>
              <li>
                <span>受領当事者は、秘密情報を本サービスの利用または提供以外の目的で利用してはならないものとする。</span>
              </li>
              <li>
                <span>前２項の規定は、受領当事者が、法令、証券取引所規則または証券業協会規則の規定に基づいて開示を要求された場合、または裁判所、官公庁または捜査機関の公的機関から開示を要求された場合には、適用されないものとします。ただし、この場合には、受領当事者は、当該開示後遅滞なく相手方にその旨を通知するものとします。</span>
              </li>
              <li>
                <span>受領当事者は、本条第２項の目的のために必要な範囲に限り、秘密情報を含む文書、電子媒体およびその他有形物、ならびに受領当事者が管理する電子媒体に記録された秘密情報を複製できるものとします。なお、この場合、受領当事者は、当該複製物を秘密情報と同様に管理および保持するものとします。</span>
              </li>
              <li>
                <span>本条の規定は、本期間の終了後、１年間有効に存続するものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第２０条（個人情報の取り扱い）</h3>
            <p>
              当社は、当社の定めるプライバシーポリシーに基づいて、契約者等の個人情報を管理および保護します。
            </p>
          </section>
          <section>
            <h3>第２１条（当社による情報利用）</h3>
            <ol>
              <li>
                <span>当社は、本サービスおよび本ソフトウェアの品質や満足度の向上を図る目的で、当社の裁量により、契約者を特定しない方法で、登録情報、本サービスの利用実績に関する情報、ログデータ等を用いた統計分析情報を作成および利用、公開をすることができます。</span>
              </li>
              <li>
                <span>当社による契約者の利用情報のうち、個人情報取り扱いについては、当社の個人情報保護方針の定めによるものとし、契約者はこの個人情報保護方針に従って当社が契約者の利用情報を取り扱うことについて同意するものとします。</span>
              </li>
              <li>
                <span>当社は、前項の場合において、業務委託先等からサービス情報が漏洩した場合において、当社の故意または重過失による場合を除き、当社は一切の責任を負いません。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第２２条（利用契約上の地位、義務の譲渡等）</h3>
            <ol>
              <li>
                <span>契約者は、当社の書面による事前の承諾なく、本契約上の地位を第三者に継承させ、または本契約に基づく権利および義務の全部または一部について、第三者に譲渡し、承継させ、担保に供しまたはその他の処分をすることができないものとします。</span>
              </li>
              <li>
                <span>当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利の移転および義務ならびに契約者の登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、契約者は、かかる譲渡につき本項において予め同意したものとみなします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第２３条（反社会的勢力の排除）</h3>
            <ol>
              <li>
                <span>契約者および当社は、自己もしくは自己の役員、重要な地位の使用人、または経営に実質的な影響力を有する株主が、現在、次の各号のいずれにも該当しないことを表明し、かつ将来にわたっても該当しないことを表明し、保証します。
                  <ol>
                    <li>
                      <span>暴力団、暴力団員、暴力団員でなくなった時から５年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、社会運動等標ぼうゴロまたは特殊知能暴力集団その他これらに準ずる者（以下総称して「暴力団員」という。）
                      </span>
                    </li>
                    <li>
                      <span>暴力団員等が経営を支配していると認められる関係を有する者
                      </span>
                    </li>
                    <li>
                      <span>自己もしくは第三者の不正の利益を図る目的または第三者に損害を加える目的をもってする等、不当に暴力団員等を利用していると認められる関係を有する者
                      </span>
                    </li>
                    <li>
                      <span>暴力団員等が経営に実質的に関与していると認められる関係を有する者
                      </span>
                    </li>
                    <li>
                      <span>暴力団員等に対して資金等を提供し、または便宜を供与する等の関与をしていると認められる関係を有する者
                      </span>
                    </li>
                    <li>
                      <span>役員または経営に実質的に関与している者が暴力団員等と社会的に非難されるべき関係を有する者
                      </span>
                    </li>
                  </ol></span>
              </li>
              <li>
                <span>契約者および当社は、自ら第三者を利用して次の各号に掲げる行為を行ってはならないものとします。
                  <ol>
                    <li><span>暴力的な要求行為</span></li>
                    <li><span>法的な責任を超えた不当な要求行為</span></li>
                    <li>
                      <span>取引に関して、脅迫的な言動をし、または暴力を用いる行為
                      </span>
                    </li>
                    <li>
                      <span>風説を流布し、偽計を用いまたは威力を用いて相手方の信用を毀損し、または相手方の業務を妨害する行為
                      </span>
                    </li>
                    <li><span>その他前各号に準ずる行為</span></li>
                  </ol></span>
              </li>
              <li>
                <span>契約者および当社は、相手方が前２項のいずれかの規定に違反した場合は、相手方の有する期限の利益を喪失させ、また通知または催告等何らの手続きを要しないで、直ちに本契約の全部または一部を解除することができます。</span>
              </li>
              <li>
                <span>契約者または当社は、前項の規定に基づく解除により違反者が被った損害について、一切の義務および責任を負わないものとします。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第２４条（契約者による解約）</h3>
            <ol>
              <li>
                <span>契約者は、当社が定める方法により、解約希望日の１か月前までに当社に通知をすることにより、本契約を解約することができます。</span>
              </li>
              <li>
                <span>前項の場合、利用料は本期間終了日まで発生し、解約日にかかわらず、日割り計算されずまた返金されません。なお、本期間終了日までの利用料に未払分がある場合には、契約者は当該未払分の利用料を、当社からの請求に応じて一括して速やかに支払うものとします。</span>
              </li>
              <li>
                <span>解約後のユーザー情報の取り扱いに関しては、第１７条、第２０条、第２１条の規定に従うものとします。</span>
              </li>
              <li>
                <span>契約者が解約した後、本サービスを利用して作成した契約者コンテンツ等に関する情報は、当社の定める一定期間の経過後に使用できなくなります。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第２５条（解約および本規約等違反に対する措置）</h3>
            <ol>
              <li>
                <span>当社は、契約者（契約者の指定したユーザーを含む。)が次の各号に掲げるいずれかに該当しまたは該当するおそれがあると当社が判断した場合には、当社の判断において、契約者に対する何らの通知または催告なく、当該契約者に対して、本サービスに掲載された情報の全部もしくは一部の削除、本サービスの利用の一時停止もしくは制限（本契約の更新の拒絶を含む。）およびユーザーIDの削除を含む本契約の解除等の措置のいずれか、またはこれらを任意に組み合わせた措置（以下総称して「利用停止等」という。）を講じることができます。ただし、当社は、契約者が次の各号に掲げるいずれかに該当しまたは該当するおそれがあると当社が判断した場合に、利用停止等の措置を講じる義務を負うものではありません。
                  <ol>
                    <li><span>本規約のいずれかの条項に違反した場合</span></li>
                    <li>
                      <span>当社に提供された情報の全部または一部について虚偽の事実があることが判明した場合</span>
                    </li>
                    <li><span>第９条第３項各号いずれかに該当する場合</span></li>
                    <li>
                      <span>本サービスの利用料金などの支払債務の履行を遅滞しまたは支払を拒否した場合</span>
                    </li>
                    <li>
                      <span>支払の停止もしくは支払不能となりまたは破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれに類する手続の申立があったとき、手形交換所の取引停止処分を受けたとき、自己を債務者とする差押、仮差押、仮処分の命令の申立があったとき、競売の申立があったとき、公租公課の滞納処分を受けたとき、またはこれらに準じる財産状態の悪化もしくは悪化するおそれがあると認められる場合</span>
                    </li>
                    <li>
                      <span>当社からの問い合わせその他の回答を求める連絡に対して１か月以上応答がない場合
                      </span>
                    </li>
                    <li>
                      <span>本規約に基づいて保証した事項が事実でなかった等、詐術その他背信的行為があった場合
                      </span>
                    </li>
                    <li>
                      <span>契約者または契約者の代理人、代表者もしくはユーザーを含む従業員等が法令に違反した場合等、当社の信用を毀損するおそれがあると当社が判断した場合
                      </span>
                    </li>
                    <li>
                      <span>その他、本契約の継続を当社が適当でないと判断した場合</span>
                    </li>
                  </ol></span>
              </li>
              <li>
                <span>契約者が前項各号のいずれかに該当するときは、契約者の当社に対するすべての債務は当然に期限の利益を失い、契約者は直ちに債務の全額を当社に支払うものとします。</span>
              </li>
              <li>
                <span>契約者は、利用停止等の後も、当社に対する本契約および本規約等の一切の義務および債務を免れるものではありません。</span>
              </li>
              <li>
                <span>当社は、本条の規定に基づいて当社が行った措置により契約者に生じた損害について、一切の責任を負いません。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第２６条（契約終了後の処理）</h3>
            <ol>
              <li>
                <span>契約者等は、終了原因のいかんにかかわらず、本契約が終了した場合には、直ちに本サービスの利用を終了するものとし、以後本サービスを利用することはできないものとします。</span>
              </li>
              <li>
                <span>前項の場合において、契約者は、本サービスの利用にあたって当社から提供を受け、端末機器等に格納されたソフトウェアおよびそれに関わるすべての資料等（当該ソフトウェアおよび資料等の複製物を含む。）について、契約者の責任で消去するものとします。</span>
              </li>
              <li>
                <span>第１８条第１項の規定にかかわらず、本サービスの運営上、本サービス上のデータを当社が保存している場合であっても、終了原因のいかんにかかわらず、本契約が終了した場合には、当社は、契約者等に通知することなく、当該終了の日から一定期間経過後に、契約者コンテンツや登録情報等の契約者等に関する情報（第１８条の規定に基づく複製データを含みますが、本サービスの利用実績に関する情報および契約者等による操作ログは含みません。）を消去します。この場合、当社は、当該消去によって契約者に生じた損害について、一切の責任を負いません。</span>
              </li>
            </ol>
          </section>
          <section>
            <h3>第２７条（準拠法および管轄裁判所の合意）</h3>
            <p>
              本規約およびサービス利用契約の準拠法は日本法とします。また契約者と当社との間で訴訟の必要が生じた場合には、東京地方裁判所を第一審の専属管轄裁判所とします。
            </p>
          </section>
        </section>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";

export default defineComponent({
  name: "TosView",
  props: {
    dialog: { type: Boolean, default: false },
  },
  emits: ["close-dialog"],
  setup(props, { emit }) {
    // 親子かんで同期するための変数
    const show_dialog = computed(() => {
      return props.dialog;
    });
    const close = () => {
      emit("close-dialog", false);
    };
    return {show_dialog, close};
  }, 
});
</script>

<style lang="scss" scoped>
.v-overlay :deep(.v-overlay__content) {
  max-height: 90%;
}

.inner-container {
  overflow: auto;
  color: #222;
  padding: 47px 60px 60px;
  background: #fff 0 0 no-repeat padding-box;
  border-radius: 4px;

  .title {
    text-align: left;
    font: normal normal 700 24px/40px Noto Sans CJK JP!important;
    margin-bottom: 16px;
    letter-spacing: .0125em!important;
  }
  .enactment {
    text-align: right;
    font: normal normal normal 13px/1 Noto Sans CJK JP !important;
    margin-bottom: 30px;
    
    p {
      margin-bottom: 16px;
    }
  }
  .content {
    text-align: left;
    font: normal normal normal 13px/26px Noto Sans CJK JP !important;

    section {
      margin-bottom: 30px;

      h2 {
        text-align: center;
        margin-bottom: 30px;
      }

      h3 {
        margin-bottom: 7px;
        font: normal normal bold 13px/26px Noto Sans CJK JP !important;
      }

      ol {
        padding-left: 24px;
        li ol {
          padding-left: 0;
          margin-left: 2.5em;

          li {
            list-style-type: none;
            counter-increment: cnt;
          }

          li::before {
            content: "（" counter(cnt) "）";
            display: inline-block;
            margin-left: -3.5em;
            width: 3em;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>
