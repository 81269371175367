<template>
  <div
    class="regional_map_popup"
    @mouseenter="onMouseover"
    @mouseleave="onMouseout"
  >
    <img
      class="close"
      src="@/assets/svg/close-small.svg"
      @click="$emit('closePopup')"
    >
    <div class="popup_content">
      <div class="popup_name">
        {{ title }}
      </div>
      <div class="popup_description">
        [業種]
        <span
          :class="{
            supermarket: storeType === storeTypes.SUPER_MARKET.value,
            drugstore: storeType === storeTypes.DRUG_STORE.value,
            homecenter: storeType === storeTypes.HOME_CENTER.value,
            cvsstore: storeType === storeTypes.CVS_STORE.value,
          }"
        >
          ●
        </span>
        <b>{{ storeTypeText }}</b>
      </div>
      <div class="popup_description">
        [シェア率] <b>{{ storeVisitCount }}%</b>
      </div>
    </div>
    <div>
      <button
        class="popup_change_store_button"
        @click="$emit('changeStore')"
      >
        基準店舗に変更
      </button>
      <button
        class="popup_move_store_button"
        @click="$emit('moveStore')"
      >
        この店舗を分析
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { STORE_TYPE } from "@/commons/enums";

export default defineComponent({
  name: "RegionalShareMapPopup",
  props: {
    title: {
      type: String,
      required: true,
    },
    storeType: {
      type: Number,
      required: true,
    },
    storeTypeText: {
      type: String,
      required: true,
    },
    storeVisitCount: {
      type: Number,
      required: true,
    },
    onMouseover: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
    onMouseout: {
      type: Function as PropType<(payload: MouseEvent) => void>,
      required: true,
    },
  },
  emits: ["closePopup", "changeStore", "moveStore"],
  data() {
    return {
      storeTypes: STORE_TYPE,
    };
  },
});
</script>

<style lang="scss" scoped>
.regional_map_popup {
  width: 220px;
  min-height: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  border-radius: 3.5px;

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
.popup_content {
  min-height: 87.5px;
  padding-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
  font: normal normal normal 12px/26px Noto Sans CJK JP;
}
.popup_change_store_button {
  height: 32.5px;
  width: 50%;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  font: normal normal normal 12px/26px Noto Sans CJK JP;

  &:focus {
    outline: none;
  }
}
.popup_move_store_button {
  height: 32.5px;
  width: 50%;
  border-top: 1px solid #dddddd;
  font: normal normal normal 12px/26px Noto Sans CJK JP;

  &:focus {
    outline: none;
  }
}
.popup_name {
  font-size: 13px;
  letter-spacing: 0px;
  color: #333333;
  font: normal normal bold 13px/19px Noto Sans CJK JP;
}
.popup_description {
  font-size: 12px;
  letter-spacing: 0px;
}
.supermarket {
  color: #e47075;
}
.drugstore {
  color: #87b8a1;
}
.homecenter {
  color: #cb9e56;
}
.cvsstore {
  color: #9278c3;
}
</style>
