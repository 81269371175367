<template>
  <div class="monthly-date-picker">
    <button
      ref="startMonthDatePicker"
      class="selector"
      @click="startMonthOpen = !startMonthOpen"
    >
      <span>{{ startMonth?.text }}</span>
      <img
        class="icon"
        src="@/assets/svg/triangle-down.svg"
      >
      <transition name="fade">
        <ul
          v-show="startMonthOpen"
          class="list"
        >
          <li
            v-for="item in startMonths"
            :key="item.value"
            class="item"
            :class="{
              'disabled-list': item.disabled,
            }"
            @click="setStartMonth(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </transition>
    </button>
    <span style="width: 28px">ー</span>
    <button
      ref="endMonthDatePicker"
      class="selector"
      @click="endMonthOpen = !endMonthOpen"
    >
      <span>{{ endMonth?.text }}</span>
      <img
        class="icon"
        src="@/assets/svg/triangle-down.svg"
      >
      <transition name="fade">
        <ul
          v-show="endMonthOpen"
          class="list"
        >
          <li
            v-for="item in endMonths"
            :key="item.value"
            class="item"
            :class="{
              'disabled-list': item.disabled,
            }"
            @click="setEndMonth(item)"
          >
            {{ item.text }}
          </li>
        </ul>
      </transition>
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import moment from "moment";

type Month = { value: string; text: string; disabled?: boolean };

export default defineComponent({
  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    loading: { type: Boolean, default: false },
  },
  data() {
    return {
      startMonthOpen: false,
      startMonth: {
        value: this.startDate,
        text: moment(this.startDate).format("YYYY年MM月"),
      } as Month,
      startMonths: [] as Month[],
      endMonthOpen: false,
      endMonth: {
        value: this.endDate,
        text: moment(this.endDate).format("YYYY年MM月"),
      } as Month,
      endMonths: [] as Month[],
    };
  },
  watch: {
    startDate(newVal) {
      this.startMonth = {
        value: newVal,
        text: moment(newVal).format("YYYY年MM月"),
      };
    },
    endDate(newVal) {
      this.endMonth = {
        value: newVal,
        text: moment(newVal).format("YYYY年MM月"),
      };
    },
    startMonth(newVal, oldVal) {
      if (newVal.value !== oldVal.value) this.updatePeriod();
    },
    endMonth(newVal, oldVal) {
      if (newVal.value !== oldVal.value) this.updatePeriod();
    },
    startMonthOpen() {
      this.startMonths = [
        this.startMonths.find(
          (m) => m.value === this.startMonth.value
        ) as Month,
        ...this.startMonths
          .filter((m) => m.value !== this.startMonth.value)
          .sort((a, b) => (a.value < b.value ? 1 : -1)),
      ].sort((a, b) => Number(a.disabled) - Number(b.disabled));
    },
    endMonthOpen() {
      this.endMonths = [
        this.endMonths.find((m) => m.value === this.endMonth.value) as Month,
        ...this.endMonths
          .filter((m) => m.value !== this.endMonth.value)
          .sort((a, b) => (a.value < b.value ? 1 : -1)),
      ].sort((a, b) => Number(a.disabled) - Number(b.disabled));
    },
  },
  created() {
    this.initStartAndEndMonths();
  },
  mounted() {
    window.addEventListener("click", this.close);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    initStartAndEndMonths() {
      this.startMonths.splice(0);
      this.endMonths.splice(0);

      // 直近13ヶ月
      const MONTH_NUM = 13;
      // 今日日付が8日より前の場合 → 13ヶ月間(2ヶ月前 の最終更新月まで)
      // 今日日付が8日以降の場合 → 13ヶ月間(1ヶ月前 の最終更新月まで)
      const SUBTRACT_NUM = moment().date() < 8 ? 2 : 1;

      for (let i = 0; i < MONTH_NUM; i++) {
        const tmp = moment().subtract(i + SUBTRACT_NUM, "month");
        this.startMonths.push({
          value: tmp.startOf("month").format("YYYY/MM/DD"),
          text: tmp.startOf("month").format("YYYY年MM月"),
          disabled: false,
        });
        this.endMonths.push({
          value: tmp.endOf("month").format("YYYY/MM/DD"),
          text: tmp.endOf("month").format("YYYY年MM月"),
          disabled: false,
        });
      }
    },
    setStartMonth(item: { value: string; text: string; disabled?: boolean }) {
      this.startMonth = {
        value: item.value,
        text: item.text,
      };
      // 開始月より過去月の選択不可
      this.endMonths = this.endMonths.map((m) => {
        m.disabled =
          moment(m.value).startOf("month").format("YYYY/MM/DD") < item.value;
        return m;
      });
    },
    setEndMonth(item: { value: string; text: string; disabled?: boolean }) {
      this.endMonth = {
        value: item.value,
        text: item.text,
      };
      // 終了月より未来月の選択不可
      this.startMonths = this.startMonths.map((m) => {
        m.disabled =
          moment(m.value).endOf("month").format("YYYY/MM/DD") > item.value;
        return m;
      });
    },
    close(event: any) {
      if (!(this.$refs.startMonthDatePicker as Element).contains(event.target))
        this.startMonthOpen = false;
      if (!(this.$refs.endMonthDatePicker as Element).contains(event.target))
        this.endMonthOpen = false;
    },
    updatePeriod() {
      this.$emit("update-period", {
        startMonth: this.startMonth.value,
        endMonth: this.endMonth.value,
      });
    },
  },
});
</script>

<style lang="scss" scoped>
.monthly-date-picker {
  display: flex;
  align-items: center;

  .selector {
    position: relative;
    display: flex;
    align-items: center;
    padding: 9px 10px 10px 21px;
    min-width: 130px;
    height: 38px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #fff;
    font-size: 13px;
    cursor: pointer;

    .icon {
      margin-top: 3px;
      margin-left: auto;
    }

    .list {
      position: absolute;
      left: -1px;
      top: -2px;
      padding: 0;
      list-style: none;
      min-width: 130px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background: #fff;
      z-index: 5;

      .item {
        padding: 9px 5px 10px 21px;
        min-width: 130px;
        height: 38px;
        text-align: left;
      }
      .item:hover {
        background-color: #f5f5f5;
      }
    }

    .selected-list {
      background: #f5f5f5;
    }
    .disabled-list {
      cursor: not-allowed;
      color: #9e9e9e;
      pointer-events: none;
    }
  }

  .selector-disabled {
    background: #e5e5e5;
    border-color: #e5e5e5;
    pointer-events: none;
  }
}

/* animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
