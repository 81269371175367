<template>
  <div class="chart-description-tooltip">
    <div
      class="wrap"
      @mouseover="setHover(true)"
      @mouseleave="setHover(false)"
    >
      <img
        src="@/assets/svg/chart-description-icon.svg"
        class="mr-2px"
      >
      <span class="text">チャート説明</span>
    </div>
    <div
      class="popover"
      :class="{ visible: hover, hidden: !hover }"
    >
      {{ description }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {useStore} from "vuex";

type MenuKey = "analytics" | "compare" | "regionalShare";
type SubMenuKey =
  | "visitors"
  | "trendVisitor"
  | "persona"
  | "visitEngagement"
  | "bizArea"
  | "regionalShare";
type ChartKey =
  | "visitors"
  | "timeDayHeatmap"
  | "dayOfWeekAverage"
  | "timeOfDayAverage"
  | "genderAgeStructure"
  | "behavioralDna"
  | "visitFrequency"
  | "engagement"
  | "cohort"
  | "distanceCoverage"
  | "bizAreaMap"
  | "ranking"
  | "comparisonOfShare"
  | "dayOfWeekShare"
  | "timeOfDayShare";

export default defineComponent({
  name: "ChartDescriptionTooltip",
  props: {
    menuKey: { type: String as PropType<MenuKey>, required: true },
    subMenuKey: { type: String as PropType<SubMenuKey>, required: true },
    chartKey: { type: String as PropType<ChartKey>, required: true },
  },
  data() {
    return {
      hover: false,
    };
  },
  computed: {
    description(): string {
      const store = useStore();
      if (!store.state.descriptions) return "";

      // NOTE: menuKey, subMenuKey, chartKey の型が推論されないため、descriptions を any で定義
      return (store.state.descriptions as any)[this.menuKey][
        this.subMenuKey
      ][this.chartKey];
    },
  },
  methods: {
    setHover(value: boolean) {
      this.hover = value;
    },
  },
});
</script>

<style lang="scss" scoped>
.chart-description-tooltip {
  position: relative;
  display: flex;
  align-items: center;

  .wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    .text {
      font: normal normal normal 12px/30px Noto Sans CJK JP;
      color: #4d99d0;
      cursor: default;
    }
  }

  .popover {
    position: absolute;
    top: 25px;
    right: 0px;
    padding: 9px 10px 14px 12px;
    border: 1px solid #707070;
    box-shadow: 2px 2px 2px #00000029;
    background: #ffffff;
    width: 320px;
    min-height: 55px;
    font-size: 11px;
    line-height: 1.75;
    user-select: none;
    white-space: pre-line;
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    z-index: 5;
    text-align: left;
  }
  .visible {
    opacity: 1;
    visibility: visible;
  }
  .hidden {
    opacity: 0;
    visibility: hidden;
  }
}

.mr-2px {
  margin-right: 2px;
}
</style>
