<template>
  <v-app-bar
    :class="{
      'pr-0': true,
      'z-99': true,
      'viewable-companies': $store.state.viewableCompanies.length > 1,
    }"
    flat
    app
    clipped-left
    color="white"
    height="76"
  >
    <router-link :to="{ name: 'Dashboard' }">
      <div class="logo">
        <mieru-logo />
      </div>
    </router-link>
    <v-divider vertical />
    <div class="left-navigation">
      <router-link
        class="left-navigation-link"
        :class="{
          'left-navigation-link-active':
            $route.meta.routeType === ROUTE_TYPE.analytics,
        }"
        :to="{
          path: '/store/analytics',
          params: {
            id: $store.state.selectedStore
              ? $store.state.selectedStore.storeId
              : null,
          },
        }"
      >
        店舗分析
      </router-link>
    </div>
    <v-divider vertical />
    <div class="left-navigation">
      <router-link
        class="left-navigation-link"
        :class="{
          'left-navigation-link-active':
            $route.meta.routeType === ROUTE_TYPE.compare,
        }"
        :to="{
          path: '/store/compare',
          params: {
            id: $store.state.selectedComparisonGroup
              ? $store.state.selectedComparisonGroup.id
              : null,
          },
        }"
      >
        店舗比較
      </router-link>
    </div>
    <v-divider vertical />
    <div class="left-navigation">
      <router-link
        class="left-navigation-link"
        :to="{ name: 'StoreArea' }"
        active-class="left-navigation-link-active"
      >
        地域シェア
      </router-link>
    </div>
    <v-divider vertical />
    <v-spacer />
    <v-divider vertical />
    <div class="right-navigation">
      <div class="right-navigation-item">
        <router-link
          class="right-navigation-link"
          :to="{ name: 'NewsList' }"
          active-class="right-navigation-link-active"
        >
          お知らせ
        </router-link>
      </div>
      <div class="right-navigation-item">
        <router-link
          class="right-navigation-link"
          :to="{ name: 'UserSetting' }"
          active-class="right-navigation-link-active"
        >
          ユーザー設定
        </router-link>
      </div>
      <div class="right-navigation-item">
        <router-link
          class="right-navigation-link"
          :to="{ name: 'Manual' }"
          active-class="right-navigation-link-active"
        >
          マニュアル
        </router-link>
      </div>
    </div>
    <button
      id="hamburger"
      class="hamburger"
      @click="showDialog = true"
    >
      <img src="@/assets/svg/menu.svg">
    </button>
    <dialog-menu
      :show-dialog="showDialog"
      @close="showDialog = false"
    />
  </v-app-bar>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import MieruLogo from "@/commons/components/SVG/MieruLogo.vue";
import DialogMenu from "@/commons/components/GlobalNavigation/DialogMenu.vue";
import { ROUTE_TYPE } from "@/commons/types/route";

export default defineComponent({
  name: "AppBar",
  components: {
    MieruLogo,
    DialogMenu,
  },
  setup() {
    const showDialog = ref(false);
    return {
      showDialog,
      ROUTE_TYPE: ROUTE_TYPE,
    };
  },
});
</script>

<style scoped>
 .v-toolbar__content {
  padding: 0;
}

.v-toolbar {
  min-width: 1013px !important;
}

.viewable-companies {
  top: 45px !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  color: #0e182e;
}

.logo:hover {
  color: #3e4657;
}

.logo-mieru:hover,
.logo-mieru:focus {
  /* #3E4657 */
  filter: invert(26%) sepia(31%) saturate(423%) hue-rotate(183deg)
    brightness(85%) contrast(85%);
}

.v-divider {
  border-color: rgb(238, 238, 238);
  margin: 0 -1px;
  width: 1px;
  opacity: 1;
}

.left-navigation {
  width: 130px;
  font-size: 14px;
  font-weight: 600;
}

.left-navigation-link {
  color: #222;
  text-decoration: none;
}

.left-navigation-link:hover {
  color: #555;
}

.left-navigation-link-active {
  color: #4d99d0;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.left-navigation-link-active:hover {
  color: #4d99d0;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

.right-navigation {
  display: flex;
  align-items: center;
  justify-content: right;
  padding: 0 21px;
  font-size: 14px;
  font-weight: 600;
}

.right-navigation-item {
  padding: 0 9.5px;
}

.right-navigation-link {
  color: #888;
  text-decoration: none;
}

.right-navigation-link:hover {
  color: #555;
  text-decoration: none;
}

.right-navigation-link-active {
  color: #4d99d0;
}

.right-navigation-link-active:hover {
  color: #4d99d0;
}

.hamburger {
  width: 77px;
  height: 77px;
  background-color: #0e182e;
}

.hamburger:hover {
  background-color: #3e4657;
}

.secondary-text {
  color: #888888;
}

.z-99 {
  z-index: 99 !important;
}
</style>

<style>
.v-toolbar__content {
  border-bottom: 1px solid #eeeeee;
}

.v-badge--dot .v-badge__badge {
  border-radius: 2.5px !important;
  min-width: 0;
  padding: 0;
  height: 5px !important;
  width: 5px !important;
}
</style>
