<template>
  <div class="view-container">
    <div class="heading">
      お知らせ
    </div>
    <LoadingImg
      v-if="loading"
      class="loading"
      :height="'60px'"
    />
    <div
      v-else-if="news.length === 0"
      class="news-content"
    >
      お知らせはありません。
    </div>
    <div
      v-else
      class="news-content"
    >
      <div
        v-for="(newsItem, index) in displayNews"
        :key="newsItem.id"
        class="news-list"
      >
        <div
          v-show="index === 0"
          class="divider"
        />
        <div class="list-content">
          <div class="list-created">
            {{ newsItem.published_at }}
          </div>
          <router-link
            class="list-title"
            :to="{ name: 'News', params: { id: newsItem.id } }"
          >
            {{ newsItem.title }}
          </router-link>
        </div>
        <div class="divider" />
      </div>
    </div>
    <pagenation
      v-model:page-index="index"
      :size="pageSize"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import Pagenation from "@/features/News/components/Pagination.vue";
import { NewsListResponse, NewsItem } from "@/features/News/types/response";
import { GetNews } from "@/features/News/axios";
import { AxiosResponse } from "axios";
import { notifyErrorMessage } from "@/plugins/notification";

export default defineComponent({
  name: "NewsListView",
  components: {
    LoadingImg,
    Pagenation,
  },
  data() {
    return {
      news: [] as NewsItem[],
      index: 1,
      loading: false,
    };
  },
  computed: {
    displayNews(): NewsItem[] {
      if (this.news.length === 0) return [];
      if (this.index === 1)
        return this.news.slice(this.index - 1, this.index + 9);
      else return this.news.slice((this.index - 1) * 10, this.index * 10);
    },
    pageSize(): number {
      if (this.news.length < 11) return 1;
      return Math.ceil(this.news.length / 10);
    },
  },
  created() {
    this.loading = true;
    GetNews()
      .then((res: AxiosResponse<NewsListResponse>) => {
        (this as any).news = res.data.news
          .sort(
            (prev, next) =>
              new Date(next.published_at).getTime() -
              new Date(prev.published_at).getTime()
          )
          .map((newsItem: NewsItem) => {
            const rowDate = new Date(newsItem.published_at);
            newsItem.published_at =
              rowDate.getFullYear() +
              "." +
              (rowDate.getMonth() + 1) +
              "." +
              rowDate.getDate();
            return newsItem;
          });
      })
      .catch(() => notifyErrorMessage("お知らせ一覧の取得に失敗しました。"))
      .finally(() => (this.loading = false));
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  margin-top: 35.5px;
  padding: 46px 60px 69px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;

  .heading {
    width: 100%;
    text-align: left;
    font: normal normal bold 24px/40px Noto Sans CJK JP;
  }
  .loading {
    margin: 40px 0 90px;
  }
  .news-content {
    width: 100%;
    margin: 40px 0 90px;

    .news-list {
      height: 60px;

      .divider {
        width: 100%;
        border: 0 solid #dddddd;
        border-top-width: 1px;
      }
      .list-content {
        height: 60px;
        display: flex;
        align-items: center;

        .list-created {
          width: 70px;
          font: normal normal normal 14px/20px Noto Sans CJK JP;
        }
        .list-title {
          margin-left: 25px;
          width: 680px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-align: initial;
          font-weight: bold;
          text-decoration: none;
          color: #4d99d0;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
