<template>
  <v-container
    class="view-container"
    fluid
  >
    <div class="view-title">
      <div class="title-text">
        <b>ユーザー登録</b>
      </div>
    </div>
    <div class="view-content">
      <div class="description">
        * 印は必須入力事項です。
      </div>
      <div class="form-row">
        <div class="form-title">
          <b>企業名:</b>
          {{ companyName }}
        </div>
      </div>
      <form onsubmit="return false;">
        <div
          v-if="!registered"
          class="form-row"
        >
          <div class="form-title">
            <b>部署名</b>
          </div>
          <custom-input
            v-model="department"
            class="form-input"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）ビーコンバンク事業部"
          />
        </div>
        <div
          v-if="!registered"
          class="form-row"
        >
          <div class="form-title">
            <b>氏名*</b>
          </div>
          <custom-input
            v-model="name"
            class="form-input"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）宇根利 太郎"
          />
        </div>
        <div class="form-row">
          <div class="form-title">
            <b>メールアドレス*（一度登録すると変更できません）</b>
          </div>
          <custom-input
            v-model="mail"
            class="form-input"
            type="email"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）宇根利 太郎"
            :readonly="true"
          />
        </div>
        <div
          v-if="!registered"
          class="form-row"
        >
          <div class="form-title">
            <b>パスワード（アルファベット・数字を含む8文字以上）*</b>
          </div>
          <custom-input
            v-model="password"
            class="form-input"
            type="password"
            width="100%"
            height="46px"
            min-width="780px"
          />
        </div>
        <div
          v-if="!registered"
          class="form-row"
        >
          <div class="form-title">
            <b>パスワード*（確認用）</b>
          </div>
          <custom-input
            v-model="confirmPassword"
            class="form-input"
            type="password"
            width="100%"
            height="46px"
            min-width="780px"
          />
        </div>
        <div class="form-footer">
          <custom-button
            class="button"
            variant="primary"
            width="220px"
            height="46px"
            :disabled="loading"
            @click="register"
          >
            登録する
          </custom-button>
        </div>
      </form>
    </div>
    <contract-conflict-dialog
      v-model="dialog"
      :loading="loading"
    />
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ContractConflictDialog from "@/features/UserSetting/components/Top/ContractConflictDialog.vue";
import * as notify from "@/plugins/notification";
import { registerUser } from "@/features/UserSetting/axios";
import { login } from "@/commons/axios/auth";
import { AxiosError } from "axios";
import { useRouter, useRoute } from 'vue-router'


export default defineComponent({
  name: "UserCreateView",
  components: { ContractConflictDialog },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const department = ref<string>();
    const name = ref<string>();
    const mail = ref<string>();
    const password = ref<string>();
    const confirmPassword = ref<string>();
    const loading = ref<boolean>(false);
    const dialog = ref<boolean>(false);
    const registered = ref<boolean>(false);
    const companyName = ref<string>();

    const created = () => {
      if (route.query.department !== undefined)
        department.value = String(route.query.department);

      if (route.query.name !== undefined)
        name.value = String(route.query.name);

      if (route.query.mail !== undefined) {
        mail.value = String(route.query.mail);
      }

      if (route.query.registered === "True") {
        registered.value = true;
      }

      if (route.query.companyName !== undefined) {
        companyName.value = String(route.query.companyName);
      }
    };
    created();

    const register = () => {
      if (loading.value) return;

      loading.value = true;

      let validate = false;

      if (
        !registered.value &&
        (typeof name.value === "undefined" || !name.value)
      ) {
        notify.notifyErrorMessage("氏名を入力してください。");
        validate = true;
      }
      if (typeof mail.value === "undefined" || !mail.value) {
        notify.notifyErrorMessage("メールアドレスを入力してください。");
        validate = true;
      } else {
        const regex = new RegExp(
          "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*.)+[a-zA-Z]{2,}$"
        );
        if (!regex.test(String(mail.value))) {
          notify.notifyErrorMessage("メールアドレスの形式が正しくありません。");
          validate = true;
        }
      }
      if (
        !registered.value &&
        (typeof password.value === "undefined" || !password.value)
      ) {
        notify.notifyErrorMessage("パスワードを入力してください。");
        validate = true;
      } else if (
        !registered.value &&
        !password.value?.match(/^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]{8,}$/)
      ) {
        notify.notifyErrorMessage(
          "アルファベットと数字を両方含む8文字以上のパスワードを入力してください。"
        );
        validate = true;
      } else if (!registered.value && password.value !== confirmPassword.value) {
        notify.notifyErrorMessage(
          "パスワードと確認用パスワードが一致しません。"
        );
        validate = true;
      }
      if (validate) {
        loading.value = false;
        return;
      }

      registerUser(
        String(name.value),
        String(mail.value),
        String(route.params.id),
        department.value,
        String(password.value)
      )
        .then(() => {
          notify.notifySuccessMessage("ユーザー登録が完了しました。");

          if (registered.value) {
            router.push({ name: "Login" });
            return;
          }
          // 登録後はログインしてダッシュボードに遷移
          login({ mail: String(mail.value), password: String(password.value) })
            .then(() => {
              router.push({ name: "Dashboard" });
            })
            .catch(() => {
              notify.notifyErrorMessage("ログインできませんでした。");
              router.push({ name: "Login" });
            });
        })
        .catch((err: AxiosError) => {
          // 上限に到達している場合はログイン画面へ
          if (
            err.response?.status === 400 &&
            (err.response?.data as { errors: string[] }).errors.includes(
              "USER_LIMIT_EXCEEDED"
            )
          ) {
            notify.notifyErrorMessage(
              "作成できるアカウント上限に達しています。"
            );
            router.push({ name: "Login" });
            return;
          }
          // 契約競合
          else if (
            err.response?.status === 400 &&
            (err.response?.data as { errors: string[] }).errors.includes(
              "CONTRACT_CONFLICT"
            )
          ) {
            dialog.value = true;
          } else {
            notify.notifyErrorMessage("ユーザー登録に失敗しました。");
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    return {
      department,
      name,
      mail,
      password,
      confirmPassword,
      loading,
      dialog,
      registered,
      companyName,
      register,
    };
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  min-width: 1180px;
  margin-top: 30px !important;
  background-color: #ffffff;
  overflow: hidden;
  padding: 40px 60px !important;
  box-shadow: 0px 0px 3px #00000029;
}
.view-title {
  height: 35px;
  display: flex;
  align-items: center;
  .title-text {
    min-width: 925px;
    width: 100%;
    font-size: 24px;
    overflow-wrap: break-word;
    float: left;
  }
}
.view-content {
  margin: 40px 0px;
  text-align: left;
  .form-row {
    margin-bottom: 30px;
  }
  .description {
    color: #222222;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .form-title {
    color: #222222;
    font-size: 15px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }
  .form-input {
    padding: 12px 17px;
    font: normal normal normal 15px/20px Noto Sans CJK JP;
  }
  .form-footer {
    text-align: end;
  }
}
.user_setting_link {
  text-decoration-line: none;
}
.button {
  font-size: 15px;
}
</style>
