<template>
  <div class="view-container">
    <div class="title">
      404 not found
    </div>
    <div class="text">
      ご指定のページまたはファイルにアクセスできませんでした。<br>ログイン後、ダッシュボードから再度お試しください。
    </div>
    <router-link :to="{ name: 'Login' }">
      ログインページへ
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NotFound",
});
</script>

<style lang="scss" scoped>
.view-container {
  margin-top: 25vh;
  width: 100%;
  height: 100%;
  text-align: center;
  font: normal normal normal 14px/30px Noto Sans CJK JP;

  div {
    margin-bottom: 20px;
  }
  .title {
    font: normal normal 700 24px/40px Noto Sans CJK JP;
    font-size: 1.25rem!important;
    letter-spacing: .0125em!important;
    font-family: Roboto,sans-serif!important;
  }
}
</style>
