<template>
  <v-container
    class="view-container"
    fluid
  >
    <div class="view-title">
      <div class="title-text">
        <b>パスワード再設定</b>
      </div>
      <div class="title-sub">
        <router-link
          class="login-link"
          :to="{ name: 'Login' }"
        >
          ログイン画面へ
        </router-link>
      </div>
    </div>
    <div class="view-content">
      <div class="description">
        * 印は必須入力事項です。
      </div>
      <form @submit.prevent="reset">
        <div class="form-row">
          <div class="form-title">
            <b>メールアドレス*</b>
          </div>
          <custom-input
            v-model="mail"
            class="form-input"
            type="email"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例) taro.unerry@example.com"
          />
        </div>
        <div class="form-action">
          <div class="form-action-content">
            <div>
              以下のボタンを押すと、ご登録のメールアドレスにパスワード再設定メールが送られます。
            </div>
            <div>
              あらかじめ、shopper-mieru.jpドメインのメールが受け取れるように設定をお願いします。
            </div>
          </div>
          <custom-button
            class="button"
            variant="primary"
            width="220px"
            height="46px"
            :diabled="loading"
          >
            再設定メールを送信
          </custom-button>
        </div>
      </form>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as notify from "@/plugins/notification";
import { passwordReset } from "@/features/UserSetting/axios";

export default defineComponent({
  name: "UserCreateView",
  data() {
    return {
      department: undefined as string | undefined,
      name: undefined as string | undefined,
      mail: undefined as string | undefined,
      loading: false,
    };
  },
  methods: {
    reset() {
      let validate = false;
      if (!this.mail) {
        notify.notifyErrorMessage("メールアドレスを入力してください。");
        validate = true;
      } else {
        const regex = new RegExp(
          "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*.)+[a-zA-Z]{2,}$"
        );
        if (!regex.test(String(this.mail))) {
          notify.notifyErrorMessage("メールアドレスの形式が正しくありません。");
          validate = true;
        }
      }
      if (validate) return;

      this.loading = true;
      passwordReset(String(this.mail))
        .then(() => {
          notify.notifySuccessMessage(
            `${this.mail} 宛に、パスワード再設定メールを送信しました。`
          );
        })
        .finally(() => {
          this.loading = true;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  height: 596px;
  min-width: 1180px;
  margin-top: 30px !important;
  background-color: #ffffff;
  overflow: hidden;
  padding: 40px 60px !important;
  box-shadow: 0px 0px 3px #00000029;
}
.view-title {
  height: 35px;
  display: flex;
  align-items: center;
  .title-text {
    min-width: 925px;
    width: 100%;
    font-size: 24px;
    overflow-wrap: break-word;
    float: left;
  }
  .title-sub {
    display: flex;
    min-width: 120px;
    font-size: 13px;
    align-items: center;
    float: right;
  }
}
.view-content {
  margin: 40px 0px;
  text-align: left;
  .form-row {
    margin-bottom: 30px;
  }
  .description {
    color: #222222;
    font-size: 14px;
    color: #222222;
    margin-bottom: 30px;
  }
  .form-title {
    color: #222222;
    font-size: 15px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }
  .form-input {
    padding: 12px 17px;
    font: normal normal normal 15px/20px Noto Sans CJK JP;
  }
  .form-footer {
    text-align: end;
  }
}
.form-action {
  text-align: center;
  letter-spacing: 0px;
  color: #222222;
  .form-action-head {
    font: normal normal bold 18px/26px Noto Sans CJK JP;
    margin-bottom: 24px;
  }
  .form-action-content {
    font: normal normal normal 13px/26px Noto Sans CJK JP;
    height: 45px;
    display: flex;
    flex-flow: column;
  }
  .custom-button {
    margin-top: 30px;
  }
}
.button {
  font-size: 15px;
}
.login-link {
  color: #4d99d0;
  text-decoration-line: none;
}
</style>
