<template>
  <div>
    <div
      class="search-box"
      :style="'width: ' + width"
    >
      <div class="search-box-inner">
        <input
          v-model="word"
          type="text"
          placeholder="店舗名、住所の一部を入力"
          @keydown.down="onKeyDown"
          @keydown.up="onKeyUp"
          @keydown.enter="onEnter"
          @focus="onFocus"
          @change="onChange"
          @input="filterStore(word)"
        >
        <img
          v-show="word.length === 0"
          src="@/assets/svg/magnify.svg"
        >
        <img
          v-show="word.length > 0"
          src="@/assets/svg/close-middle.svg"
          class="cursor-pointer"
          @click.prevent="close"
        >
      </div>
      <div
        v-if="filtered.length > 0"
        class="list-container"
        :style="'width: ' + listWidth"
      >
        <ul>
          <li
            v-for="p of filtered"
            :key="p.storeId"
            :class="{ 'is-focused': isFocused(p) }"
            @click="selectStore(p)"
            @mouseover="setFocus(p)"
            @mouseleave="unsetFocus(p)"
          >
            <span class="name-address">
              <span class="name">{{ p.name }}</span>
              <span class="address">{{ p.address }}</span>
            </span>
          </li>
        </ul>
      </div>
      <div
        v-if="isNotFound"
        class="not-found"
        :style="'width: ' + listWidth"
      >
        キーワードに合致する結果が⾒つかりませんでした。
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from "vue";
import { RegexTable } from "@/commons/components/StoreSelector/RegexTable";
import { Store } from "@/commons/interfaces/responses/store";
import { useStore } from "vuex";
import { debounce } from "lodash";

const LIMIT = 300;

export default defineComponent({
  name: "SearchAutoComplete",
  props: {
    width: { type: String, default: "100%" },
    listWidth: { type: String, default: "100%" },
    type: { type: String as PropType<"vuex" | "emit">, default: "vuex" },
  },
  emits: ["setStore"],
  setup(props, { emit }){
    const store = useStore();
    const word = ref("");
    const storeBase = useStore();
    const filtered = ref<Store[]>([]);
    const focused = ref<Store | null>(null);
    const isNotFound = ref(false);
    
    const selectedStore = computed(() => {      
      return storeBase.state.selectedStore;
    });
    const created = () => {
      if (selectedStore.value !== null)
        word.value = selectedStore.value.name;
    };
    created();
    watch(selectedStore, () => {
      if (selectedStore.value !== null)
        word.value = selectedStore.value.name;
    });

    const selectStore = (store: Store) => {
      filtered.value = [];
      focused.value = null;
      // Vuex の持ち回る店舗を更新
      if (props.type === "vuex")
        storeBase.commit("setStore", { store: store });
      // 親の setStore を実行
      if (props.type === "emit") {
        word.value = store.name;
        emit("setStore", { store: store });
      }
    };
    const close = () => {
      filtered.value = [];
      word.value = "";
      isNotFound.value = false;
    };
    const onEnter = (event: KeyboardEvent) => {
      if (focused.value && !event.isComposing) {
        selectStore(focused.value);
      } else {
        if (word.value.length > 0) {
          let ret = filterStore(word.value);
        }
      }
    };

    const stores = computed(() => {      
      return store.state.stores;
    });

    const filterStore = debounce((val: string) => {
      isNotFound.value = false;

      let word = val.trim();
      if (word.length == 0) {
        filtered.value = [];
        return filtered.value;
      }

      let words = word.split(/\s+/).filter((s) => s.length > 0);

      // 入力内容を正規表現化
      words = words.map((w) => {
        return w.replace(/[a-zA-Zａ-ｚＡ-Ｚ０-９0-9&＆.*+{}[\]-]/g, (c) => {
          return RegexTable[c];
        });
      });

      let regexps = words.map((w) => new RegExp(w, "i"));

      // 店舗をフィルタリング
      filtered.value = stores.value
        .filter((store: Store) => {
          return regexps.every((regexp) => {
            return store.name.match(regexp) || store.address.match(regexp);
          });
        })
        .sort((a: Store, b: Store) => a.orderIndex - b.orderIndex)
        .slice(0, LIMIT);

      isNotFound.value = 0 == filtered.value.length;
    }, 200);
    
    const setFocus = (store: Store) => {
      focused.value = store;
    };
    const unsetFocus = (store: Store) => {
      if (focused.value === store) focused.value = null;
    };
    const isFocused = (store: Store) => {
      return focused.value === store;
    };
    const onKeyUp = () => {
      if (filtered.value.length === 0) {
        if (word.value.length > 0) {
          filterStore(word.value);
          if (filtered.value.length === 0) return;
        }
      }
      if (!focused.value) {
        focused.value = filtered.value[filtered.value.length - 1];
      } else {
        focused.value =
          filtered.value[
            (filtered.value.indexOf(focused.value) + filtered.value.length - 1) %
              filtered.value.length
          ];
      }
    };
    const onKeyDown = () => {
      if (0 === filtered.value.length) {
        if (0 < word.value.length) {
          filterStore(word.value);
          if (0 === filtered.value.length) return;
        }
      }
      if (!focused.value) {
        focused.value = filtered.value[0];
      } else {
        focused.value =
          filtered.value[
            (filtered.value.indexOf(focused.value) + 1) % filtered.value.length
          ];
      }
    };
    const onFocus = () => {
      if (0 < word.value.length) filterStore(word.value);
    };
    const onChange = () => {
      focused.value = null;
    };

    watch(stores, () => {
      if (0 < filtered.value.length) {
        focused.value = null;
        filterStore(word.value);
      }
    });

    return {
      word,
      filtered,
      focused,
      isNotFound,
      selectStore,
      close,
      onEnter,
      filterStore,
      setFocus,
      unsetFocus,
      isFocused,
      onKeyUp,
      onKeyDown,
      onFocus,
      onChange,
    };
  },
});
</script>

<style scoped>
.search-box {
  position: relative;
}
.search-box-inner {
  display: flex;
  padding: 11px 15px 13px;
  width: 100%;
  height: 44px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff;
  font-size: 14px;
}
.search-box-inner:hover {
  border: 1px solid #999999;
}
.search-box-inner:focus {
  outline: 1px solid #999999;
}
.search-box-inner input {
  margin-right: auto;
  width: 100%;
  height: 100%;
  outline: none;
}
.search-box-inner input::placeholder {
  color: #999999;
}
.list-container {
  position: absolute;
  top: 47px;
  left: 0;
  padding: 14px 16px;
  max-height: 350px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 4;
  color: #000000;
}
.list-container ul {
  text-align: left;
  list-style: none;
  padding: 0;
}
.list-container ul li {
  cursor: pointer;
  height: 36px;
  padding: 8px 11px 8px 9px;
}
.is-focused {
  background: #f5f5f5;
}
.name-address {
  display: flex;
  align-content: center;
}
.name-address .name {
  font-weight: bold;
  font-size: 14px;
}
.name-address .address {
  margin-left: 13px;
  font-size: 13px;
}
.not-found {
  position: absolute;
  top: 47px;
  left: 0;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 13px;
  z-index: 2;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
