<template>
  <LoadingImg
    v-if="loading"
    class="loading"
    :height="'60px'"
  />
  <div
    v-else
    class="view-container"
  >
    <div class="news-date">
      {{ publishedAt }}
    </div>
    <div class="news-title">
      {{ title }}
    </div>
    <div
      class="news-content"
      v-html="body"
    />
    <div class="news-footer">
      <div class="footer-column previous-link">
        <router-link
          v-if="!isEmptyPreviousNewsId"
          :to="{ name: 'News', params: { id: previousNewsId } }"
        >
          古い記事へ
        </router-link>
      </div>
      <div class="footer-column">
        <router-link :to="{ name: 'NewsList' }">
          お知らせ一覧へ
        </router-link>
      </div>
      <div class="footer-column next-link">
        <router-link
          v-if="!isEmptyNextNewsId"
          :to="{ name: 'News', params: { id: nextNewsId } }"
        >
          新しい記事へ
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { NewsResponse } from "@/features/News/types/response";
import { GetNewsFromId } from "@/features/News/axios";
import { AxiosResponse } from "axios";
import { notifyErrorMessage } from "@/plugins/notification";
import { marked } from "marked";
import { onBeforeRouteLeave, useRoute } from 'vue-router'

export default defineComponent({
  name: "NewsListView",
  components: {
    LoadingImg,
  },
 
  setup() {
    const route = useRoute();
    const loading = ref(false);
    const publishedAt = ref("");
    const title = ref("");
    const body = ref("");
    const previousNewsId = ref("");
    const nextNewsId = ref("");
    const renderer = ref(new marked.Renderer());

    const isEmptyPreviousNewsId = computed((): boolean => {
      if (previousNewsId.value) return false;
      return true;
    });

    const isEmptyNextNewsId = computed((): boolean => {
      if (nextNewsId.value) return false;
      return true;
    });

    const loadNews = (newsId: string) => {
      loading.value = true;
      GetNewsFromId(newsId)
        .then((res: AxiosResponse<NewsResponse>) => {
          const rowDate = new Date(res.data.published_at);
          publishedAt.value =
            rowDate.getFullYear() +
            "." +
            (rowDate.getMonth() + 1) +
            "." +
            rowDate.getDate();
          title.value = res.data.title;
          body.value = marked(res.data.body);
          previousNewsId.value = res.data.previous_news_id;
          nextNewsId.value = res.data.next_news_id;
        })
        .catch(() => notifyErrorMessage("お知らせの取得に失敗しました。"))
        .finally(() => (loading.value = false));
    };

    onBeforeRouteLeave((to, from, next) => {
      loadNews(to.params.id as string);
      next();
    });

    const created = () => {
      // markedの初期設定
      const linkRenderer = renderer.value.link;
      renderer.value.link = (href: string, title: string, text: string) => {
        const html = linkRenderer.call(renderer.value, href, title, text);
        return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
      };
      marked.setOptions({ breaks: true, renderer: renderer.value });

      loadNews(route.params.id as string);
    };
    created();
  
    return {
      loading,
      publishedAt,
      title,
      body,
      previousNewsId,
      nextNewsId,
      renderer,
      isEmptyPreviousNewsId,
      isEmptyNextNewsId,
      loadNews,

    };
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  margin-top: 35.5px;
  padding: 30px 60px 69px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;

  .news-date {
    margin-bottom: 18px;
    text-align: right;
    font: normal normal normal 13px/40px Noto Sans CJK JP;
    letter-spacing: 0px;
    color: #666666;
  }

  .news-title {
    margin-bottom: 35px;
    width: 100%;
    padding: 19px 25px;
    background: #0e182e 0% 0% no-repeat padding-box;
    text-align: left;
    font: normal normal bold 16px/32px Noto Sans CJK JP;
    letter-spacing: 0px;
    color: #ffffff;
  }
  .loading {
    margin: 40px 0 90px;
  }
  .news-content {
    width: 100%;
    margin: 35px 0 0px;
    text-align: left;
    font: normal normal normal 14px/30px Noto Sans CJK JP;
    letter-spacing: 0px;
    color: #222222;

    :deep(p) {
      margin-bottom: 16px;
    }
  }
  .news-footer {
    width: 100%;
    margin-top: 66px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    .footer-column {
      width: calc(100% / 3);
      a {
        text-decoration: none;
        color: #4d99d0;
      }
    }
    .previous-link {
      text-align: start;
      a {
        text-decoration: none;
        color: #4d99d0;
      }
    }
    .next-link {
      text-align: end;
      a {
        text-decoration: none;
        color: #4d99d0;
      }
    }
  }
}
</style>
