<template>
  <div class="dashboard-tab">
    <hr color="#cccccc">
    <ul>
      <li
        v-for="(tab, index) in tabs"
        :key="index"
      >
        <router-link
          :to="{ name: tab.name }"
          class="router-link"
        >
          <history-icon
            v-if="tab.name === 'History'"
            :color="isActive(tab.path) ? '#4d99d0' : '#666666'"
            class="icon"
          />
          <label
            :class="{
              'active-text': isActive(tab.path),
              'font-bold': isActive(tab.path) && tab.name === 'History',
            }"
            v-text="tab.text"
          />
        </router-link>
        <div
          v-show="isActive(tab.path)"
          class="active-bar"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HistoryIcon from "@/commons/components/SVG/HistoryIcon.vue";

export default defineComponent({
  name: "DashboardTab",
  components: {
    HistoryIcon,
  },
  data() {
    return {
      tabs: [
        {
          text: "お気に入り条件",
          name: "FavoriteList",
          path: "/dashboard/favorite/list",
        },
        {
          text: "店舗比較リスト",
          name: "CompareList",
          path: "/dashboard/compare/list",
        },
        { text: "更新履歴", name: "History", path: "/dashboard/history" },
      ],
    };
  },
  computed: {
    routePath() {
      return this.$route.path;
    },
  },
  methods: {
    isActive(path: string) {
      return this.routePath.indexOf(path) > -1;
    },
  },
});
</script>

<style lang="scss" scoped>
.dashboard-tab {
  display: flex;
  flex-direction: column;

  hr {
    position: relative;
    transform: translateY(38px);
  }

  ul {
    display: flex;
    width: 100%;
    padding: 0;
    list-style: none;

    li {
      float: left;
      margin: 0;
      width: 150px;
      font-size: 15px;
      font-weight: bold;
      font-family: Noto Sans CJK JP;
      letter-spacing: 0px;
      color: #222222;

      &:last-child {
        margin-left: auto;
        margin-top: 3px;
        font-size: 13px;
        font-weight: normal;
      }

      .router-link {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;

        .icon {
          margin-bottom: 10px;
          margin-right: 9px;
        }

        label {
          display: block;
          cursor: pointer;
          margin-bottom: 12px;
          color: #222;
        }

        .active-text {
          color: #4d99d0;
        }

        .font-bold {
          font-weight: bold;
        }
      }

      input {
        display: none;
      }

      .active-bar {
        position: relative;
        background: #519ace;
        height: 5px;
        border-radius: 9999px;
      }
    }
  }
}
</style>
