<template>
  <div class="company-selector-bar">
    <div class="company-selector">
      <label for="companySelector">企業選択：</label>
      <select
        id="companySelector"
        v-model="viewCompany"
        name="企業選択"
      >
        <option
          v-for="company in viewableCompanies"
          :key="company.id"
          :value="company"
        >
          {{ company.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";

import { Company } from "@/features/Dashboard/types";
import { useStore } from "vuex";

export default defineComponent({
  name: "CompanySelector",
  setup() {
    const store = useStore();
    const viewableCompanies =  ref<Company[]>([]);
    const viewCompany =  ref<Company | null>(null);

    const fetchData = async() => {
      if (!store.state.viewableCompanies.length)
        await store.dispatch("fetchViewableCompanies");
      if (!store.state.viewCompany)
        await store.dispatch("fetchViewableCompanies");
    };
    const refreshCompanies = () => {
      viewCompany.value = store.state.viewCompany;
      viewableCompanies.value = store.state.viewableCompanies;
    };

    const created = async() => {
      const store = useStore();
      await fetchData();
      refreshCompanies();
    };
    created();
    watch(viewCompany, (newVal, oldVal) => {
      if (oldVal === null && newVal !== null) return;
      if (newVal?.id === oldVal?.id) return;
      store.dispatch("setViewCompany", newVal);
      location.reload();
    });

    return {
      viewableCompanies,
      viewCompany,
      fetchData,
      refreshCompanies,
    }
  },
});
</script>

<style lang="scss" scoped>
.company-selector-bar {
  position: fixed;
  top: 0;
  left: 0px;
  right: 0px;
  z-index: 99;

  height: 45px;
  padding: 0px 15px;
  background: #5fafe8;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.company-selector {
  margin-right: 3px;
  margin-bottom: 3px;
  label {
    margin-right: 3px;
    font: normal normal 600 12px/17px Noto Sans CJK JP;
    color: #ffffff;
  }
  select {
    width: 305px;
    border-bottom: 1px solid #ffffff;
    padding: 0 4px 0 4px;
    font: normal normal 600 12px/26px Noto Sans CJK JP;
    color: #ffffff;

    text-overflow: ellipsis;
    white-space: nowrap;

    option {
      font: normal normal 500 13px/26px Noto Sans CJK JP;
      color: #222222;
    }
  }
  select:focus-visible {
    outline: none;
  }
}
</style>
