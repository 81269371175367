import { useCookies } from "vue3-cookies";

import axios, { AxiosInstance } from "axios";
import { API_URL } from "@/config";
import router from '@/router/index';

const axiosClient: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
  timeout: 0, // unlimited
  withCredentials: true,
});



axiosClient.interceptors.request.use(  
  (config: any) => {
    if (router?.currentRoute.value.meta?.requiredAuth) {
      // 認証が必要なページにのみトークンをheaderに付与
      const {cookies} = useCookies();
      config.headers["X-CSRFToken"] = cookies?.get("csrftoken");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosClient };
