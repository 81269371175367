import { ComparisonGraph } from "@/features/StoreCompare/types";
import { ChartData } from "@/features/StoreCompare/trendVisitor/types";
import { DAYS } from "@/commons/enums";
import { maxWithNaN, minWithNaN, sumWithNaN } from "@/commons/utils/mathUtil";

/**
 * 曜日別シェア率チャート用に加工する関数
 * @param graphs { storeName: "店舗A", visitCounts: { chartItems: [{ granularity: "月" isAlert: false, value: 752.5 }, ... ], chartType: 0 }}
 * @returns [
 *   ["店舗A", { value: 100, maxValue: 200, isAlert: false }],
 *   ["店舗B", { value: 130, maxValue: 200, isAlert: false }],
 *   ...
 * ]
 */
export function processDayChart(graphs: ComparisonGraph[]) {
  if (graphs.length === 0) return [];

  // 元データをソート
  graphs.sort((a, b) => {
    return a.orderIndex - b.orderIndex;
  });

  let sumValue = 0;
  let minValue = NaN;
  let maxValue = NaN;
  // 最終的に返す配列
  const processed: ChartData = graphs.map((graph) => {
    const min = Object.values(graph.visitCounts.chartItems).reduce((x, y) => {
      if (x.value === null && y.value === null) return x;
      else if (x.value !== null && y.value === null) return x;
      else if (x.value === null && y.value !== null) return y;
      else return x.value < y.value ? x : y;
    });
    if (isNaN(minValue)) minValue = min.value;
    else if (min.value !== null && minValue === null) minValue = min.value;
    else if (min.value !== null && min.value < minValue) minValue = min.value;
    const max = Object.values(graph.visitCounts.chartItems).reduce((x, y) => {
      if (x.value === null && y.value === null) return x;
      else if (x.value !== null && y.value === null) return x;
      else if (x.value === null && y.value !== null) return y;
      else return x.value > y.value ? x : y;
    });
    if (isNaN(maxValue)) maxValue = max.value;
    else if (max.value !== null && maxValue === null) minValue = min.value;
    else if (max.value !== null && max.value > maxValue) maxValue = max.value;
    graph.visitCounts.chartItems.forEach((item) => {
      sumValue += Math.round(item.value) ?? 0;
    });
    const tmp = graph.visitCounts.chartItems.map((item) => {
      const v = !item.value ? NaN : Math.round(item.value);
      return {
        value: v,
        minValue: 0,
        maxValue: 0,
        isAlert: item.isAlert ? item.isAlert : false,
        shareRatio: 0.0,
      };
    });
    return [graph.storeName, ...tmp];
  });

  processed.forEach((values, i) => {
    values.forEach((value, j) => {
      if (j === 0) return;

      const v = (
        value as {
          value: number;
          minValue: number;
          maxValue: number;
          isAlert: boolean;
          shareRatio: number;
        }
      ).value;

      (
        processed[i][j] as {
          value: number;
          minValue: number;
          maxValue: number;
          isAlert: boolean;
          shareRatio: number;
        }
      ).minValue = Math.round(minValue);
      (
        processed[i][j] as {
          value: number;
          minValue: number;
          maxValue: number;
          isAlert: boolean;
          shareRatio: number;
        }
      ).maxValue = Math.round(maxValue);
      if (!(isNaN(v) || sumValue === 0)) {
        (
          processed[i][j] as {
            value: number;
            minValue: number;
            maxValue: number;
            isAlert: boolean;
            shareRatio: number;
          }
        ).shareRatio = v / sumValue;
      }
    });
  });
  return processed;
}

/**
 * 時間別シェア率チャート用に平日と土日祝日のデータを統合して加工する関数
 * @param graphs { storeName: "店舗A", visitCounts: { chartItems: [{ granularity: "8" isAlert: false, value: 752.5 }, ... ], chartType: 0 }}
 * @returns [
 *   ["店舗A(平日)", { value: 100, maxValue: 200, isAlert: false }],
 *   ["店舗A(土日祝日)", { value: 100, maxValue: 200, isAlert: false }],
 *   ["店舗B(平日)", { value: 130, maxValue: 200, isAlert: false }],
 *   ["店舗B(土日祝日)", { value: 130, maxValue: 200, isAlert: false }],
 *   ...
 * ]
 */
export function processTimeChart(
  comparisonGraph: ComparisonGraph[]
): ChartData {
  if (comparisonGraph.length === 0) return [];

  // 平日・休日ごとの集計データ（初期値）
  const initialSummary = {
    weekday: {
      min: NaN,
      max: NaN,
      sum: NaN,
    },
    holiday: {
      min: NaN,
      max: NaN,
      sum: NaN,
    },
    from: (subIndex: number | null | undefined) => {
      if (subIndex === DAYS.WEEKDAYS.value) return summary.weekday;
      if (subIndex === DAYS.HOLIDAYS.value) return summary.holiday;
      throw new Error("subIndexの値が不正です");
    },
  };

  const summary = comparisonGraph.reduce((prev, current) => {
    // 欲しいsubIndexの値だけを取り出す
    const values = (dayIdentifier: number) =>
      current.visitCounts.chartItems
        .filter((c) => c.subIndex === dayIdentifier)
        .map((c) => c.value ?? NaN);

    return {
      weekday: {
        min: minWithNaN([...values(DAYS.WEEKDAYS.value), prev.weekday.min]),
        max: maxWithNaN([...values(DAYS.WEEKDAYS.value), prev.weekday.max]),
        sum: sumWithNaN([...values(DAYS.WEEKDAYS.value), prev.weekday.sum]),
      },
      holiday: {
        min: minWithNaN([...values(DAYS.HOLIDAYS.value), prev.holiday.min]),
        max: maxWithNaN([...values(DAYS.HOLIDAYS.value), prev.holiday.max]),
        sum: sumWithNaN([...values(DAYS.HOLIDAYS.value), prev.holiday.sum]),
      },
      from: initialSummary.from,
    };
  }, initialSummary);

  // 元データをソート
  const graphs: ComparisonGraph[] = comparisonGraph
    .map((graphItem) => {
      graphItem.visitCounts.chartItems = graphItem.visitCounts.chartItems.sort(
        (a, b) =>
          // 時間帯昇順でソート
          Number(a.granularity.split("-")[0]) >
          Number(b.granularity.split("-")[0])
            ? 1
            : -1
      );
      // 平日と土日祝日を仕分けする
      const visitCounts = {
        weekDay: graphItem.visitCounts.chartItems.filter(
          (c) => c.subIndex === DAYS.WEEKDAYS.value
        ),
        holiday: graphItem.visitCounts.chartItems.filter(
          (c) => c.subIndex === DAYS.HOLIDAYS.value
        ),
      };
      if (visitCounts.weekDay.length !== visitCounts.holiday.length)
        throw new Error("時間別チャートの値が不正です");
      // 平日と土日祝日を確実に交互に並べる
      graphItem.visitCounts.chartItems = visitCounts.weekDay
        .map((_, i) => [visitCounts.weekDay[i], visitCounts.holiday[i]])
        .flat();
      return graphItem;
    })
    .sort((a, b) => {
      return a.orderIndex - b.orderIndex;
    });

  return graphs.map((graph) => [
    graph.storeName,
    ...graph.visitCounts.chartItems.map((c) => {
      return {
        value: c.value,
        isAlert: c.isAlert ?? true,
        minValue: summary.from(c.subIndex).min,
        maxValue: summary.from(c.subIndex).max,
        shareRatio: c.value / summary.from(c.subIndex).sum,
      };
    }),
  ]);
}
