<template>
  <v-container
    class="pa-0"
    fluid
    style="min-width: 1180px"
  >
    <v-row
      class="regional-share-title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          地域シェア
        </h1>
        <update-badge
          type="month"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <monthly-date-picker
        :start-date="start"
        :end-date="end"
        @update-period="updatePeriod"
      />
    </v-row>
    <div class="chart-description-tooltip-warp">
      <chart-description-tooltip
        menu-key="regionalShare"
        sub-menu-key="regionalShare"
        chart-key="map"
      />
    </div>
    <v-row class="ma-0">
      <v-card
        :width="'100%'"
        class="pa-0"
      >
        <regional-share-map
          :loading="regionalMapLoading || storeLoading"
          :store-point="storePoint"
          :radius="radius"
          :max-value="maxValue"
          :base-features="baseFeatures"
          :comp-features="filteredCompFeatures"
        />
        <div class="reginal_map_footer">
          <div class="footer_content_head">
            <b>業種</b>
          </div>
          <div class="footer_content_body">
            <button
              v-for="storeType in enableStoreTypes"
              :key="storeType.id + '_' + storeType.name"
              class="store_type_badge"
              :class="{
                supermarket: storeType.id === storeTypes.SUPER_MARKET.value,
                drugstore: storeType.id === storeTypes.DRUG_STORE.value,
                homecenter: storeType.id === storeTypes.HOME_CENTER.value,
                cvsstore: storeType.id === storeTypes.CVS_STORE.value,
                inactive_badge: !selectedStoreTypes.includes(storeType.id),
              }"
              @click="onClickStoreType(storeType.id)"
            >
              <img :src="getIconPath(storeType.id)">
              <span class="badge_content">{{ storeType.name }}</span>
            </button>
          </div>
          <div class="footer_content_head">
            <b>表示範囲</b>
          </div>
          <div class="footer_content_body">
            <span class="radius_affix">0km</span>
            <vue-slider
              v-model.number="radius"
              class="radius_slider"
              :dot-size="15"
              :width="170"
              :height="5"
              :min="0"
              :max="15"
              :interval="0.1"
            >
              <template #dot="{ focus }">
                <div :class="['custom-dot', { focus }]" />
              </template>
            </vue-slider>
            <span class="radius_affix">15km</span>
            <input
              v-model.number="radius"
              class="radius_input"
              type="number"
              min="0"
              max="15"
              step="0.1"
            >
            <span style="font-size: 13px">km</span>
          </div>
          <v-spacer />
          <div class="footer_content_button">
            <custom-button
              width="120px"
              height="38px"
              :disabled="
                !selectedStoreTypes.length ||
                  regionalMapLoading ||
                  regionalTableLoading
              "
              class="mr-20px"
              @click="applyConditions"
            >
              適用する
            </custom-button>
          </div>
        </div>
        <div class="divider" />
        <v-card-title class="table_title d-flex align-center">
          <v-spacer />
          <chart-description-tooltip
            menu-key="regionalShare"
            sub-menu-key="regionalShare"
            chart-key="table"
            class="mr-27px"
          />
          <download-button
            label="CSVデータ"
            :disabled="!selectedStore"
            :get-file-id="getRegionalShareFileId"
            :csv-name="regionalShareCSVName"
          />
        </v-card-title>
        <regional-share-table
          :loading="regionalTableLoading"
          :base-data="baseTableData"
          :comp-datas="compTableData"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :load-finished="tableDataLoadFinished"
          @sort="sortTableData"
        />
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import MonthlyDatePicker from "@/commons/components/DatePicker/MonthlyDatePicker.vue";
import RegionalShareMap from "@/features/RegionalShare/components/RegionalShareMap.vue";
import RegionalShareTable from "@/features/RegionalShare/components/RegionalShareTable.vue";

import { getPeriodByRouterQueryMonthPeriod } from "@/commons/utils";
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import {
  getInitialStartDate,
  getInitialEndDate,
  processingBaseStoreData,
  processingCompStoreData,
  getMaxValue,
  processingBaseStoreTableData,
  processingCompStoreTableData,
} from "@/features/RegionalShare/utils";
import { STORE_TYPE } from "@/commons/enums";
import { FILTER_TYPE } from "@/features/RegionalShare/enums";
import {
  getStoreTypes,
  getStoreRegionMap,
  getStoreRegionTable,
  downloadRegionalShareChart,
} from "@/features/RegionalShare/axios";
import { AxiosResponse } from "axios";
import { Store } from "@/commons/interfaces/responses/store";
import { MapBoxFeatures } from "@/features/RegionalShare/interfaces/components";
import {
  StoreTypeResponse,
  StoreRegionMapResponse,
  StoreRegionTableResponse,
  StoreRegionTableItem,
} from "@/features/RegionalShare/interfaces/response";
import * as notify from "@/plugins/notification";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router'

import checkCircleGrayImg from "@/assets/svg/check-circle-gray.svg";
import checkCircleRedImg from "@/assets/svg/check-circle-red.svg";
import checkCircleGreenImg from "@/assets/svg/check-circle-green.svg";
import checkCircleYellowImg from "@/assets/svg/check-circle-yellow.svg";
import checkCirclePurpleImg from "@/assets/svg/check-circle-purple.svg";

const checkCircleImgPath = {
  gray: checkCircleGrayImg,
  red: checkCircleRedImg,
  green: checkCircleGreenImg,
  yellow: checkCircleYellowImg,
  purple: checkCirclePurpleImg,
};

export default defineComponent({
  name: "RegionalShareView",
  components: {
    MonthlyDatePicker,
    RegionalShareMap,
    RegionalShareTable,
    UpdateBadge,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const created = ref(false);
    const start = ref(getInitialStartDate() as string);
    const end = ref(getInitialEndDate() as string);
    const selectedStoreTypes = ref([
      STORE_TYPE.SUPER_MARKET.value,
      STORE_TYPE.DRUG_STORE.value,
      STORE_TYPE.HOME_CENTER.value,
      STORE_TYPE.CVS_STORE.value,
    ] as number[]);
    const enableStoreTypes = ref<{
      id: number;
      name: string;
    }[]>([]);
    const radius = ref(1);
    const regionalMapLoading = ref(false);
    const regionalTableLoading = ref(false);
    const storePoint = ref<{ lat: number; lng: number } | undefined>(undefined);
    const storeLoading = ref(false);
    const baseFeatures = ref<MapBoxFeatures[]>([]);
    const compFeatures = ref<MapBoxFeatures[]>([]);
    const maxValue = ref(0);
    const baseTableData = ref<StoreRegionTableItem>();
    const compTableData = ref<StoreRegionTableItem[]>([]);
    const sortBy = ref();
    const sortDesc = ref(false);
    const tableDataLoadFinished = ref(false);

    const selectedStore = computed((): Store | null => {
      return store.state.selectedStore;
    });
    const filterType = computed((): (typeof FILTER_TYPE)[keyof typeof FILTER_TYPE] => {
      return store.state.regionalShareFilterType;
    });
    const filteredCompFeatures = computed((): MapBoxFeatures[] => {
      const filterTypeValue = filterType.value.value;
      // シェア上位20店舗
      if (filterTypeValue === FILTER_TYPE.SHARE_RANK.value) {
        const tmp = compFeatures.value.slice();
        return tmp
          .sort((a, b) => b.properties.visitCount - a.properties.visitCount)
          .slice(0, 20);
      }

      // 基準店舗とシェアが近い20店舗
      if (filterTypeValue === FILTER_TYPE.BASE_AND_SHARE_RANK.value) {
        const tmp = compFeatures.value.slice();
        return tmp
          .sort((a, b) => {
            const baseVisitCount =
              baseFeatures.value[0].properties.visitCount ?? 0;
            return (
              Math.abs(a.properties.visitCount - baseVisitCount) -
              Math.abs(b.properties.visitCount - baseVisitCount)
            );
          })
          .slice(0, 20);
      }

      // 全て
      return compFeatures.value;
    });
    const hasRouterQueryPeriod = computed((): boolean => {
      return route.query.period !== undefined;
    });
    const hasRouterQueryChannels = computed((): boolean => {
      if (route.query.channels === undefined || route.query.channels === null) return false;
      if (typeof route.query.channels === "string") {
        return !isNaN(Number(route.query.channels));
      } else {
        if (
          route.query.channels.some(
            (channel: string | null) =>
              channel === null || isNaN(Number(channel))
          )
        )
          return false;
      }
      return true;
    });
    const hasRouterQueryRadius = computed((): boolean => {
      if (route.query.radius === undefined) return false;
      if (isNaN(Number(route.query.radius))) return false;
      return true;
    });
    const getRegionalShareFileId = computed((): () => Promise<AxiosResponse<any, any>> => {
      const array = Array.from(
        new Set(
          selectedStoreTypes.value.filter((storeType: number) => {
            if (availableStoreType(String(storeType)))
              return String(storeType);
          })
        )
      );
      return () =>
        downloadRegionalShareChart(
          selectedStore.value?.storeId,
          array,
          radius.value
        );
    });
    const regionalShareCSVName = computed((): string => {
      const { start, end } = {
        start: convertSlashDelimiter(store.state.startMonth),
        end: convertSlashDelimiter(store.state.endMonth),
      };
      return `地域シェア_${start}-${end}`;
    });

    const setDateFromRouterQuery = () => {
      if (hasRouterQueryPeriod.value) {
        const tmp = getPeriodByRouterQueryMonthPeriod(route.query.period as string);
        if (tmp !== undefined) {
          start.value = tmp.startMonth;
          end.value = tmp.endMonth;
        }
      }
    };
    const setChannelsFromRouterQuery = () => {
      if (hasRouterQueryChannels.value) {
        selectedStoreTypes.value = [];
        // 型チェックでクエリパラメータが文字列でないことを担保しないとmap出来ないのでelse ifで確認しておく
        if (typeof route.query.channels === "string")
          selectedStoreTypes.value.push(Number(route.query.channels));
        else if (typeof route.query.channels !== "string") {
          route.query.channels?.forEach((channel: string | null) => {
            if (channel !== null) selectedStoreTypes.value.push(Number(channel));
          });
        }
      }
    };
    const setRadiusFromRouterQuery = () => {
      if (hasRouterQueryRadius.value && Number(route.query.radius) > 0)
        radius.value = Number(route.query.radius);
    };

    const create = async() => {
      // クエリパラメータからデータ更新
      setDateFromRouterQuery();
      setChannelsFromRouterQuery();
      setRadiusFromRouterQuery();

      storeLoading.value = true;

      // 店舗一覧
      if (!store.state.stores.length)
        await store.dispatch("fetchStores");

      // id があれば選択店舗を設定
      if (route.params["id"])
        await store.dispatch("specifiedStore", route.params["id"]);

      await getStoreTypes().then((res: AxiosResponse<StoreTypeResponse>) => {
        enableStoreTypes.value = res.data.storeTypes;
      });

      if (selectedStore.value)
        storePoint.value = {
          lat: selectedStore.value.latitude,
          lng: selectedStore.value.longitude,
        };

      storeLoading.value = false;

      // データ取得
      if (selectedStore.value) applyConditions();

      created.value = true;
    };
    create();
    
    const getIconPath = (storeType: number) => {
      const color = (() => {
        switch (storeType) {
          case STORE_TYPE.SUPER_MARKET.value:
            return selectedStoreTypes.value.includes(storeType) ? "red" : "gray";
          case STORE_TYPE.DRUG_STORE.value:
            return selectedStoreTypes.value.includes(storeType)
              ? "green"
              : "gray";
          case STORE_TYPE.HOME_CENTER.value:
            return selectedStoreTypes.value.includes(storeType)
              ? "yellow"
              : "gray";
          case STORE_TYPE.CVS_STORE.value:
            return selectedStoreTypes.value.includes(storeType)
              ? "purple"
              : "gray";
          default:
            return "gray";
        }
      })();
      return checkCircleImgPath[color];
    };
   
    const availableStoreType = (channel: string | null) => {
      return enableStoreTypes.value.some(
        (enableStoreType: { id: number; name: string }) =>
          enableStoreType.id === Number(channel)
      );
    };
    const updatePeriod = (period: { startMonth: string; endMonth: string }) => {
      start.value = period.startMonth;
      end.value = period.endMonth;
    };
    const onClickStoreType = (storeType: number) => {
      if (selectedStoreTypes.value.includes(storeType))
        selectedStoreTypes.value = selectedStoreTypes.value.filter(
          (value: number) => value !== storeType
        );
      else selectedStoreTypes.value.push(storeType);
    };
    const applyConditions = async() => {
      if (!selectedStore.value) {
        notify.notifyErrorMessage("基準となる店舗を選択してください。");
        return;
      }
      storePoint.value = {
        lat: selectedStore.value.latitude,
        lng: selectedStore.value.longitude,
      };
      await pushRoute();
      await getMapData();
      await getTableData();
    };
    const pushRoute = async() => {
      const tmp = {
        start: convertHyphenDelimiter(start.value),
        end: convertHyphenDelimiter(end.value),
      };

      // 選択範囲外の業種を除外(念の為重複排除)
      const array = Array.from(
        new Set(
          selectedStoreTypes.value
            .filter((storeType: number) => {
              if (availableStoreType(String(storeType)))
                return String(storeType);
            })
            .map(String)
        )
      );

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (route.name === "StoreArea") {
        await router
          .replace({
            name: "StoreArea",
            params: { id: selectedStore.value?.storeId },
            query: {
              period: `${tmp.start}_${tmp.end}`,
              channels: array,
              radius: String(radius.value),
            },
          })
          .catch(() => undefined);
      }
    };
    const getMapData = async() => {
      regionalMapLoading.value = true;
      const array = Array.from(
        new Set(
          selectedStoreTypes.value.filter((storeType: number) => {
            if (availableStoreType(String(storeType)))
              return String(storeType);
          })
        )
      );
      await getStoreRegionMap(
        selectedStore.value?.storeId,
        array,
        radius.value,
        convertHyphenDelimiter(start.value),
        convertHyphenDelimiter(end.value)
      )
        .then((res: AxiosResponse<StoreRegionMapResponse>) => {
          if (
            res.data.baseStore.visitCount === null ||
            (res.data.compStores.length >= 1 &&
              res.data.compStores.every((c) => c.visitCount === null))
          )
            throw new Error("地域シェアテーブルデータが不正です");
          maxValue.value = getMaxValue(res.data);
          baseFeatures.value = processingBaseStoreData(res.data);
          compFeatures.value = processingCompStoreData(res.data);
        })
        .catch(() => {
          baseFeatures.value = [];
          compFeatures.value = [];
          notify.notifyErrorMessage(
            "地域シェアマップデータの取得に失敗しました。"
          );
        })
        .finally(() => {
          regionalMapLoading.value = false;
        });
    };
    const getTableData = async() => {
      regionalTableLoading.value = true;
      baseTableData.value = undefined;
      compTableData.value = [];
      sortBy.value = undefined;

      const array = Array.from(
        new Set(
          selectedStoreTypes.value.filter((storeType: number) => {
            if (availableStoreType(String(storeType)))
              return String(storeType);
          })
        )
      );
      await getStoreRegionTable(selectedStore.value?.storeId, array, radius.value)
        .then((res: AxiosResponse<StoreRegionTableResponse>) => {
          if (
            res.data.baseStore.visitCount === null ||
            (res.data.compStores.length >= 1 &&
              res.data.compStores.every((c) => c.visitCount === null))
          )
            throw new Error("地域シェアテーブルデータが不正です");

          baseTableData.value = processingBaseStoreTableData(res.data);
          compTableData.value = processingCompStoreTableData(res.data);
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "地域シェアテーブルデータの取得に失敗しました。"
          );
        })
        .finally(() => {
          regionalTableLoading.value = false;
          tableDataLoadFinished.value = true;
        });
    };
    const sortTableData = (sortKey: any) => {
      if (sortBy.value === sortKey) sortDesc.value = !sortDesc.value;
      else {
        sortBy.value = sortKey;
        sortDesc.value = false;
      }
      compTableData.value = compTableData.value.slice().sort(
        (a: StoreRegionTableItem, b: StoreRegionTableItem) => {
          if (sortKey === "distance") {
            if (sortDesc.value) return a.distance < b.distance ? 1 : -1;
            else return a.distance > b.distance ? 1 : -1;
          } else {
            let aValue = 0,
              bValue = 0;
            a.visitCount.forEach((item: { date: string; value: number }) => {
              if (sortKey === item.date) aValue = item.value;
            });
            b.visitCount.forEach((item: { date: string; value: number }) => {
              if (sortKey === item.date) bValue = item.value;
            });
            if (sortDesc.value) return aValue < bValue ? 1 : -1;
            else return aValue > bValue ? 1 : -1;
          }
        }
      );
    };

    watch(selectedStore, () => {
      if (created.value) {
        applyConditions();
      }
    });

    return {
      start,
      end,
      storeTypes: STORE_TYPE,
      selectedStoreTypes,
      enableStoreTypes,
      radius,
      regionalMapLoading,
      regionalTableLoading,
      storePoint,
      storeLoading,
      baseFeatures,
      maxValue,
      baseTableData,
      compTableData,
      sortBy,
      sortDesc,
      tableDataLoadFinished,
      selectedStore,
      filteredCompFeatures,
      getRegionalShareFileId,
      regionalShareCSVName,
      getIconPath,
      updatePeriod,
      onClickStoreType,
      applyConditions,
      sortTableData,
    };
  },
});
</script>

<style scoped>
.regional-share-title-container {
  display: flex;
  align-items: center;
  margin-top: 32.5px !important;
  margin-bottom: 10px !important;
}
.reginal_map_footer {
  align-items: center;
  display: flex;
  height: 59px;
  border-top: 1px solid #eeeeee;
  background-color: #ffffff;
  justify-content: space-between;
  width: 100%;
  min-width: 1100px;
}
.footer_content_head {
  height: 100%;
  width: 80px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_content_body {
  height: 100%;
  display: flex;
  background: #ffffff 0% 0% no-repeat padding-box;
  padding: 0px 18px;
  align-items: center;
}
.footer_content_button {
  height: 100%;
  display: flex;
  align-items: center;
}
.store_type_badge {
  width: 130px;
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 100px;
  opacity: 1;
  font-size: 12px;
  padding: 0px 9px;
  text-align: start;
  align-items: center;
  display: flex;
  margin-right: 8px;
}
.supermarket {
  border: 2px solid #e47075;
}
.drugstore {
  border: 2px solid #87b8a1;
}
.homecenter {
  border: 2px solid #cb9e56;
}
.cvsstore {
  border: 2px solid #a993d3;
}
.inactive_badge {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  color: #999999;
}
.badge_content {
  width: 100%;
  text-align: center;
}
.radius_head {
  width: 80px;
  height: 59px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  opacity: 1;
}
.radius_slider {
  margin-right: 7.5px;
  margin-left: 10.5px;
}
.custom-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #4d99d0;
}
input[type="range"] {
  overflow: hidden;
  width: 170px;
  -webkit-appearance: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 10px;
  -webkit-appearance: none;
  margin-top: -1px;
}
input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  height: 15px;
  -webkit-appearance: none;
  background: #4d99d0;
  box-shadow: -170px 0 0 170px #519ace;
}
.radius_input {
  margin-right: 5px;
  margin-left: 10px;
  width: 50px;
  height: 28px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  text-align: right;
  font-size: 14px;
}
.radius_affix {
  font-size: 12px;
  color: #666666;
}
.regional_share_table {
  margin-bottom: 30px;
}
.table_title {
  padding: 0 46px 19px 46px;
}
.divider {
  border-bottom: 5px solid #eee;
  margin: 0 0 28px 0;
}
.mr-20px {
  margin-right: 20px;
}
.mr-27px {
  margin-right: 27px;
}
.chart-description-tooltip-warp {
  display: flex;
  justify-content: flex-end;
}
</style>
