<template>
  <div class="card-container mb-0">
    <v-card-title class="mb-25px pa-0 d-flex align-center">
      <div>
        <div class="bizAreaTableTitle">
          <b>商圏エリア別シェア獲得率比較（β）</b>
          <alert-tooltip v-if="privacyAlertAreaNames.length || hasCntAlert">
            <p
              v-if="hasCntAlert"
              :class="{ 'mb-0': !privacyAlertAreaNames.length }"
            >
              ・取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。
            </p>
            <p
              v-if="privacyAlertAreaNames.length"
              class="mb-0"
            >
              ・次の地域は世帯数が少ないため、プライバシーに配慮し非表示としています:
              <span class="font-bold">{{
                privacyAlertAreaNames.join(", ")
              }}</span>
            </p>
          </alert-tooltip>
        </div>
        <div class="baseStoreText">
          基準店舗：{{ !baseStore ? "未選択" : baseStore.name }}
        </div>
      </div>
      <v-spacer />
      <chart-description-tooltip
        menu-key="compare"
        sub-menu-key="bizArea"
        chart-key="comparisonOfShare"
        class="mr-27px"
      />
      <download-button
        label="CSVデータ"
        :disabled="!$store.state.selectedComparisonGroup"
        :get-file-id="getFileId"
        :csv-name="csvName"
      />
    </v-card-title>
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="py-0">
          <div
            v-if="!areas.length"
            class="no-area-overlay"
          >
            <span>基準となる条件を選択してください</span>
          </div>
          <no-data-chart v-else-if="isZeroData" />
          <biz-area-table
            v-else
            :areas="areasWithStoreName"
            :base-store-id="baseStoreId"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import AlertTooltip from "@/commons/components/Elements/AlertTooltip.vue";
import BizAreaTable from "@/features/StoreCompare/bizArea/components/BizAreaTable.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
import { ComparisonGroup } from "@/commons/interfaces";
import { ComparisonGroupStore } from "@/features/StoreCompare/types";
import { Area } from "@/features/StoreCompare/bizArea/types";
import { deepCopy } from "@/commons/utils";
import { AxiosResponse } from "axios";

export default defineComponent({
  name: "BizAreaTableCard",
  components: {
    BizAreaTable,
    AlertTooltip,
    NoDataChart,
  },
  props: {
    areas: {
      type: Array as PropType<Area[]>,
      required: true,
    },
    baseStore: {
      type: Object as PropType<ComparisonGroupStore | undefined>,
      default: undefined,
    },
    privacyAlertAreaNames: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    getFileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      required: true,
    },
    csvName: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasCntAlert(): boolean {
      return this.areas.some((area) =>
        area.stores.some((store) => store.isAlert)
      );
    },
    areasWithStoreName(): Area[] {
      const deepCopiedAreas = deepCopy(this.areas);
      return deepCopiedAreas.map((area) => {
        const stores = (
          this.$store.state.selectedComparisonGroup as ComparisonGroup
        ).stores
          .sort((a, b) => a.orderIndex - b.orderIndex)
          .map((store) => {
            const targetStore = area.stores.find((s) => s.storeId === store.id);
            return {
              storeId: store.id,
              storeName: store.name,
              visitRatio: targetStore?.visitRatio ?? NaN,
              isAlert: targetStore?.isAlert ?? false,
            };
          });
        area.stores = stores;
        return area;
      });
    },
    baseStoreId(): string {
      return this.baseStore ? this.baseStore.id : "";
    },
    isZeroData(): boolean {
      return this.areas.every((area: Area) =>
        area.stores.every(
          (store) => store.visitRatio === 0 || store.visitRatio === null
        )
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0;
}
.mb-25px {
  margin-bottom: 25px;
}
.mr-27px {
  margin-right: 27px;
}
.font-bold {
  font-weight: bold;
}
.card-container {
  height: 100%;
  .no-area-overlay {
    background: #eeeeee;
    width: 100%;
    height: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.bizAreaTableTitle {
  display: flex;
  b {
    margin-right: 8px;
    font: normal normal bold 14px/30px Noto Sans CJK JP;
  }
}
.baseStoreText {
  text-align: left;
  font: normal normal normal 14px/30px Noto Sans CJK JP;
  color: #666666;
}
</style>
