export const WEEKS = {
  SUNDAY: { text: "日", value: 0 },
  MONDAY: { text: "月", value: 1 },
  TUESDAY: { text: "火", value: 2 },
  WEDNESDAY: { text: "水", value: 3 },
  THURSDAY: { text: "木", value: 4 },
  FRIDAY: { text: "金", value: 5 },
  SATURDAY: { text: "土", value: 6 },
} as const;

export const GENDER = {
  MALE: { text: "男性", value: 1 },
  FEMALE: { text: "女性", value: 2 },
} as const;

export const AGE = {
  TEEN: { text: "10代", value: 1 },
  TWENTIES: { text: "20代", value: 2 },
  THIRTIES: { text: "30代", value: 3 },
  FORTIES: { text: "40代", value: 4 },
  FIFTIES: { text: "50代", value: 5 },
  SIXTIES: { text: "60代〜", value: 6 },
} as const;

export const SUB_TYPE = {
  NONE: 0,
  GENDER: 1,
  AGE: 2,
} as const;

export const PARTICLE_TYPE = {
  DATE: 1,
  WEEK: 2,
  MONTH: 3,
  HOUR: 4,
  DAY: 5,
} as const;

export const COLOR = {
  RED: "#DE5A69",
  BLUE: "#4D99D0",
  GREEN: "#76AA9A",
  ORANGE: "#DEA156",
  PURPLE: "#AA83AA",
  BROWN: "#843900",
  GRAY: "#9E9E9E",
  RED2: "#EEACB3",
  BLUE2: "#A5CCE7",
  TOTAL: "#55A07C",
  TOTAL2: "#A9CFBD",
} as const;

export const POINT_STYLE = {
  RED: {
    default:
      "point { fill-color: #DE5A69; stroke-color: #DE5A69; stroke-width: 2 }",
    alert:
      "point { fill-color: #FFFFFF; stroke-color: #DE5A69; stroke-width: 2 }",
  },
  BLUE: {
    default:
      "point { fill-color: #4D99D0; stroke-color: #4D99D0; stroke-width: 2 }",
    alert:
      "point { fill-color: #FFFFFF; stroke-color: #4D99D0; stroke-width: 2 }",
  },
  GREEN: {
    default:
      "point { fill-color: #76AA9A; stroke-color: #76AA9A; stroke-width: 2 }",
    alert:
      "point { fill-color: #FFFFFF; stroke-color: #76AA9A; stroke-width: 2 }",
  },
  ORANGE: {
    default:
      "point { fill-color: #DEA156; stroke-color: #DEA156; stroke-width: 2 }",
    alert:
      "point { fill-color: #FFFFFF; stroke-color: #DEA156; stroke-width: 2 }",
  },
  PURPLE: {
    default:
      "point { fill-color: #AA83AA; stroke-color: #AA83AA; stroke-width: 2 }",
    alert:
      "point { fill-color: #FFFFFF; stroke-color: #AA83AA; stroke-width: 2 }",
  },
  BROWN: {
    default:
      "point { fill-color: #843900; stroke-color: #843900; stroke-width: 2 }",
    alert:
      "point { fill-color: #FFFFFF; stroke-color: #843900; stroke-width: 2 }",
  },
  GRAY: {
    default:
      "point { fill-color: #9E9E9E; stroke-color: #9E9E9E; stroke-width: 2 }",
    alert:
      "point { fill-color: #FFFFFF; stroke-color: #9E9E9E; stroke-width: 2 }",
  },
} as const;

export const STORE_TYPE = {
  // TODO: 表記をapiとフロントで揃えるため、/api/store-types/から受け取った値を格納するようにしたい
  SUPER_MARKET: { text: "スーパー", value: 1 },
  DRUG_STORE: { text: "ドラッグストア", value: 2 },
  HOME_CENTER: { text: "ホームセンター", value: 3 },
  CVS_STORE: { text: "コンビニ", value: 4 },
  toLocalString: (value: number) => {
    switch (value) {
      case STORE_TYPE.SUPER_MARKET.value:
        return STORE_TYPE.SUPER_MARKET.text;
      case STORE_TYPE.DRUG_STORE.value:
        return STORE_TYPE.DRUG_STORE.text;
      case STORE_TYPE.HOME_CENTER.value:
        return STORE_TYPE.HOME_CENTER.text;
      case STORE_TYPE.CVS_STORE.value:
        return STORE_TYPE.CVS_STORE.text;
      default:
        return "";
    }
  },
} as const;

export const DAYS = {
  WEEKDAYS: { text: "平日", value: 1 },
  HOLIDAYS: { text: "土日祝日", value: 2 },
} as const;
