<template>
  <v-container fluid>
    <v-overlay
      contained
      :model-value="hasAlert"
      content-class="w-100 h-100"
      scrim="rgba(0,0,0,0)"
      :z-index="3"
      :persistent="true"
      :no-click-animation="true"
    >
      <div
        class="d-flex align-center justify-center w-100 h-100"
        style="background-color: rgba(0, 0, 0, 0.6);"
      >
        <div class="overlay-message">
          <p>取得データボリュームが少なく、</p>
          <p>統計上の信頼性の低いデータが含まれています。</p>
          <p>参考値としてご参照ください。</p>
          <v-btn
            variant="text"
            style="text-decoration: underline"
            @click="onClickVOverlay()"
          >
            閉じる
          </v-btn>
        </div>
      </div>
    </v-overlay>
    <GChart
      type="BarChart"
      :data="chartData"
      :options="options"
      :events="event"
      @ready="initChart"
    />
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { GChart } from "vue-google-charts";

export default defineComponent({
  name: "BizAreaRankingChart",
  components: {
    GChart,
  },
  props: {
    chartData: {
      type: Array as PropType<(string | number)[][]>, required: true,
    },
    hasAlert: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["clickChart", "clickApproveAlert"],
  setup(props, { emit }) {
    let bizAreaRankingChart = null as any;

    const initChart = (chart: any) => {
      bizAreaRankingChart = chart;
    };

    const options = computed(
      (): any => { // vue-google-chartsの型定義がいいかげんなのでanyで
      let height = props.chartData.length * 33 + 30;

      return {
        chartArea: {
          top: 10,
          left: 300,
          right: 100,
          bottom: 50,
        },
        colors: ["71BEA6"],
        hAxis: {
          format: "percent",
          textStyle: {
            fontSize: 12,
          },
          minValue: 0,
        },
        height: height,
        legend: { position: "none" },
        tooltip: {
          isHtml: true,
        },
        vAxis: {
          textStyle: {
            fontSize: 10,
          },
        },
        bar: {
          groupWidth: 17,
        },
      };
    },
    );
    const onClickVOverlay = () => {
      emit("clickApproveAlert");
    };

    return {
      event: {
        select: () => {
          emit(
            "clickChart",
            props.chartData[(bizAreaRankingChart.getSelection()[0].row ?? 0) + 1][0]
          );
        },
      } as any, // vue-google-chartsの型定義がいいかげんなのでanyで
      options,
      initChart,
      onClickVOverlay,
    };
  },   
});
</script>

<style lang="scss" scoped>
.overlay-message {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: white;

  p {
    margin-bottom: 11px;
  }
}
</style>
