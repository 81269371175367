<template>
  <button
    ref="customSelector"
    class="custom-selector"
    :class="{
      xs: size === 'xs',
      sm: size === 'sm',
      md: size === 'md',
      disabled: disabled,
    }"
    :style="`width: ${width} !important; height: ${height} !important`"
    @click="open = !open"
    @keydown.enter="selectItem(focused)"
    @keydown.up.prevent="onKeyUp()"
    @keydown.down.prevent="onKeyDown()"
  >
    <span v-if="modelValue">{{ modelValue?.text }}</span>
    <span
      v-else
      class="placeholder"
    >{{ placeholder }}</span>
    <img
      class="triangle-down"
      src="@/assets/svg/triangle-down.svg"
    >
    <transition name="fade">
      <ul
        v-show="open"
        class="list"
        :style="`width: ${width}; max-height: ${listHeight} !important`"
      >
        <li
          v-for="item in sortedItems"
          :key="item.value"
          :class="{
            xs: size === 'xs',
            sm: size === 'sm',
            md: size === 'md',
            'is-focused': isFocused(item),
          }"
          :style="`height: ${height}`"
          class="list-item"
          @click="selectItem(item)"
          @mouseover="setFocus(item)"
          @mouseleave="unsetFocus(item)"
        >
          {{ item?.text }}
        </li>
      </ul>
    </transition>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

type Item = { value: number | string; text: string };

export default defineComponent({
  props: {
    modelValue: { type: Object as PropType<Item>, default: undefined },
    items: { type: Array as PropType<Item[]>, default: () => { return [] } },
    size: { type: String as PropType<"sm" | "md" | "xs">, default: "md" },
    width: { type: String, default: "210px" },
    height: { type: String, default: "38px" },
    numberOfDisplay: { type: Number, default: 7 },
    placeholder: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      open: false,
      focused: null as Item | null,
      sortedItems: [] as Item[],
    };
  },
  computed: {
    listHeight(): string {
      return (
        Number(this.height.replace(/[^0-9]/g, "")) * this.numberOfDisplay + "px"
      );
    },
  },
  watch: {
    open() {
      if (this.open && this.modelValue !== undefined && this.items !== undefined)
        this.sortedItems = [
          this.modelValue,
          ...this.items.filter(
            (item) => item.value !== this.modelValue?.value
          ),
        ];
    },
  },
  mounted() {
    window.addEventListener("click", this.close);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    selectItem(item: Item | null) {
      if (item === null) return;
      this.$emit("update:modelValue", item);
    },
    setFocus(item: Item) {
      this.focused = item;
    },
    unsetFocus(item: Item) {
      if (this.focused === item) this.focused = null;
    },
    isFocused(item: Item) {
      return this.focused === item;
    },
    onKeyUp() {
      if (this.items === undefined || this.items.length === 0) return;
      if (!this.focused) {
        this.focused = this.sortedItems[this.items.length - 1];
      } else {
        this.focused =
          this.sortedItems[
            (this.sortedItems.indexOf(this.focused) + this.items.length - 1) %
              this.items.length
          ];
      }
    },
    onKeyDown() {
      if (this.items === undefined || this.items.length === 0) return;
      if (!this.focused) {
        this.focused = this.sortedItems[0];
      } else {
        this.focused =
          this.sortedItems[
            (this.sortedItems.indexOf(this.focused) + 1) % this.items.length
          ];
      }
    },
    close(event: any) {
      if (!(this.$refs.customSelector as Element).contains(event.target)) {
        this.open = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.custom-selector {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  color: #222222;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  .placeholder {
    color: #999999;
  }
}

.custom-selector:focus {
  outline: none;
}

.disabled {
  background: #e5e5e5;
  border-color: #e5e5e5;
  pointer-events: none;
}

// size
.xs {
  font-size: 11px;
}
.sm {
  font-size: 13px;
}
.md {
  font-size: 14px;
}

.list {
  position: absolute;
  top: -1px;
  left: -1px;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  overflow-y: scroll;
  z-index: 6;

  .list-item {
    padding-left: 10px;
    padding-bottom: 2px;
    display: flex;
    align-items: center;
  }
  .is-focused {
    background-color: #f5f5f5;
  }
}
.list::-webkit-scrollbar {
  display: none;
}

.triangle-down {
  margin-left: auto;
  margin-right: 12.35px;
}

/* animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
