import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/validator";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
import Notifications from '@kyvg/vue3-notification'

import AlertCaption from "@/commons/components/Elements/AlertCaption.vue";
import AlertTooltip from "@/commons/components/Elements/AlertTooltip.vue";
import CustomButton from "@/commons/components/Elements/CustomButton.vue";
import CustomCheckbox from "@/commons/components/Elements/CustomCheckbox.vue";
import CustomInput from "@/commons/components/Elements/CustomInput.vue";
import CustomSelector from "@/commons/components/Elements/CustomSelector.vue";
import CustomTextArea from "@/commons/components/Elements/CustomTextArea.vue";
import DownloadButton from "@/commons/components/Elements/DownloadButton.vue";
import ChartDescriptionTooltip from "@/commons/components/Elements/ChartDescriptionTooltip.vue";

import vuetify from "./plugins/vuetify";
import vuetifyDev from "./plugins/vuetify-dev";
import '@/styles/vuetify.override.scss'

const app = createApp(App);

app.component("VueSlider", VueSlider);
// custom component
app.component("AlertCaption", AlertCaption);
app.component("AlertTooltip", AlertTooltip);
app.component("CustomButton", CustomButton);
app.component("CustomCheckbox", CustomCheckbox);
app.component("CustomInput", CustomInput);
app.component("CustomSelector", CustomSelector);
app.component("CustomTextArea", CustomTextArea);
app.component("DownloadButton", DownloadButton);
app.component("ChartDescriptionTooltip", ChartDescriptionTooltip);

app.use(router);
app.use(store);
app.use(process.env.NODE_ENV === "production" ? vuetify : vuetifyDev);
app.use(Notifications);

app.mount("#app");

export default app;